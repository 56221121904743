import { IAffiliateApi } from './AffiliateApi'
import Affiliate from '../../model/Affiliate'
import { MOCK_DELAY_MS, MOCK_AFFILIATES } from '../MockData'

export class MockAffiliateApi implements IAffiliateApi {

  createAffiliate(affiliate: Affiliate): Promise<Affiliate> {
    const existingAffiliate = MOCK_AFFILIATES.find(a => a.id === affiliate.id)
    if (existingAffiliate !== undefined) {
      throw { status: 409, message: "Affiliate already exists" }
    }

    const serverAffiliate: Affiliate = Object.assign({}, affiliate)
    serverAffiliate.id = serverAffiliate.name
    MOCK_AFFILIATES.push(serverAffiliate)

    return new Promise((resolve) => setTimeout(() => resolve(Object.assign({}, serverAffiliate)), MOCK_DELAY_MS))
  }

  updateAffiliate(affiliate: Affiliate): Promise<Affiliate> {
    const existingAffiliate = MOCK_AFFILIATES.find(a => a.id === affiliate.id)
    if (existingAffiliate === undefined) {
      throw {error: 404, message: `No affiliate with id '${affiliate.id}'`}
    }

    existingAffiliate.name = affiliate.name
    existingAffiliate.inputFeedIds = Object.assign([], affiliate.inputFeedIds)
    existingAffiliate.outputIds = Object.assign([], affiliate.outputIds)
    return new Promise((resolve) => setTimeout(() => resolve(Object.assign({}, existingAffiliate)), MOCK_DELAY_MS))
  }


  fetchAffiliatesForUser(username: string): Promise<Affiliate[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        //Take advantage of the "prefix affiliateId with username" hack
        const affiliatesForUser = MOCK_AFFILIATES.filter(a => a.id.startsWith(username))
        return resolve(Object.assign([], affiliatesForUser))
      }, MOCK_DELAY_MS)
    })
  }

  deleteAffiliate(affiliateId: string): Promise<void> {
    const index = MOCK_AFFILIATES.findIndex(affiliate => affiliate.id === affiliateId)
    if (index > -1) {
      MOCK_AFFILIATES.splice(index, 1);
    }
    return new Promise((resolve) => setTimeout(() => resolve(), MOCK_DELAY_MS))
  }
}

