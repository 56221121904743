import React from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import { createStyles, Theme, WithStyles, FormControlLabel } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { GlobalState } from '../../redux/reducers/RootReducer'
import { connect } from 'react-redux'
import Output from '../../model/Output'
import FeedItem from './FeedItem'
import HeaderRow from '../common/header/HeaderRow'
import { defaultParentInset, headerRowHeight } from '../../styles'
import { MockOutputApi } from '../../api/output/MockOutputApi'
import { OutputSelectors } from '../../redux/selectors/OutputSelectors'
import ActivateFeedIcon from '@material-ui/icons/PresentToAll'
import DeactivateFeedIcon from '@material-ui/icons/CancelPresentation'
import ViewFeedIcon from '@material-ui/icons/Fullscreen'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import Button from '@material-ui/core/Button/Button'
import Radio from '@material-ui/core/Radio/Radio'
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup'
import ViewFeedPage from './ViewFeedPage'
import InputFeed from '../../model/InputFeed'
import { InputFeedSelectors } from '../../redux/selectors/InputFeedSelectors'
import { ImageFetcher, ImageFetcherDelegate } from '../../ImageFetcher'

//FIXME:
// 1 create new affiliate with only 1 input feed, select it and navigate here (componentDidMount), then select the Demo Affiliate --> only 1 image will be updated
// Solution: we need to update the images to be either when changing selected affiliate or on componentDidUpdate()
const inputItemsPerRow = 3.0

const styles = (theme: Theme) => createStyles({
    root: {
      //backgroundColor: 'pink',
      width: '100%'
    },

    inputGrid: {
      //backgroundColor: 'blue',

      overflowY: 'scroll',

      margin: 0,
      padding: `0px ${defaultParentInset}px 0px 0px` //Keep the grid to the left, with 24px spacing to the right (towards the output grid)
    },

    outputGrid: {
      //backgroundColor: 'green',

      overflowY: 'scroll',

      margin: 0,

      /**
       * Padding: Keep the grid to the right, with spacing to the left (towards the input grid)
       *
       * To keep the item sizes between the input and output grids:
       * - inputGrid has 3 children per row and 24px horizontal padding
       * - outputGrid has 1 child per row and should therefore have 24px/3 = 8px horizontal padding
       */
      padding: `0px 0px 0px ${defaultParentInset / inputItemsPerRow}px`
    },

    inputGridItemContainer: {
      //backgroundColor: 'orange',
      width: `calc(100.0% / ${inputItemsPerRow})`, //3 items per row
      padding: '0px 4px 8px 4px',
      margin: 0
    },
    outputGridItemContainer: {
      //backgroundColor: 'purple',
      width: '100%',
      padding: '0px 4px 8px 4px',
      margin: 0
    },

    gridItemContainerAspectRatio: {
      //backgroundColor: 'red',
      position: 'relative',
      width: '100%',
      height: 0,
      paddingTop: '75%', //'height: 0' and 'paddingTop: 75' === 4:3 aspect ratio
      margin: 0,
      padding: 0
    },
    gridItem: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    }
  }
)

enum PageState {
  DEFAULT = "DEFAULT",
  SELECTION_IN_PROGRESS = "SELECTION_IN_PROGRESS",
  CONFIRM_SELECTION = "CONFIRM_SELECTION",
  VIEWING_FEED = "VIEWING_FEED"


}

type GlobalStateProps = ReturnType<typeof mapStateToProps>

interface Props extends GlobalStateProps, WithStyles<typeof styles> {}

interface State {
  pageState: PageState

  inputFeedToView?: InputFeed
  inputFeedToActivate?: InputFeed | null
  outputToUpdate?: Output

  // The height of the frame of the scrollview
  gridHeight: number

  imagesByInputFeedId: { [inputFeedId: string] : string }
}


/**
 * User drags an input to an output --> confirmActivateInputRequest()       --> this.activateInput()
 * User selects an input            --> handleInputSelected()               --> this.activateInput()
 * User deselects an output         --> confirmDeactivateOutputRequest      --> this.activateInput(null)
 */
class ManageFeedsPage extends React.PureComponent<Props, State> implements ImageFetcherDelegate {
  private imageFetcher: ImageFetcher

  constructor(props: Props) {
    super(props)

    this.imageFetcher = new ImageFetcher(this)
    this.startFetchingThumbnails = this.startFetchingThumbnails.bind(this)
    this.onImageFetchedForKey = this.onImageFetchedForKey.bind(this)

    this.getInitialState = this.getInitialState.bind(this)
    this.calculateGridHeight = this.calculateGridHeight.bind(this)
    this.resetToDefaultState = this.resetToDefaultState.bind(this)
    this.handleResize = this.handleResize.bind(this)

    this.onDragStart = this.onDragStart.bind(this)
    this.onDragOver = this.onDragOver.bind(this)
    this.onDrop = this.onDrop.bind(this)

    this.handleInputSelected = this.handleInputSelected.bind(this)
    this.confirmActivateInputRequest = this.confirmActivateInputRequest.bind(this)
    this.confirmDeactivateOutputRequest = this.confirmDeactivateOutputRequest.bind(this)

    this.activateInputFeed = this.activateInputFeed.bind(this)

    this.renderSelectionInProgress = this.renderSelectionInProgress.bind(this)
    this.renderConfirmSelection = this.renderConfirmSelection.bind(this)

    this.viewFeed = this.viewFeed.bind(this)
    this.handleViewFeedDismissed = this.handleViewFeedDismissed.bind(this)

    this.state = this.getInitialState()
  }

  private getInitialState(): State {
    return {
      pageState: PageState.DEFAULT,
      inputFeedToActivate: undefined,
      outputToUpdate: undefined,
      inputFeedToView: undefined,
      gridHeight: this.calculateGridHeight(window.innerHeight),
      imagesByInputFeedId: Object.assign({}, this.imageFetcher.fetchedImagesById)
    }
  }

  private calculateGridHeight(windowHeight: number): number {
    // Take app bar header + grid header heights + some additional margin into account
    return windowHeight - (headerRowHeight + headerRowHeight)
  }

  private resetToDefaultState() {
    this.setState(this.getInitialState())
  }


  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.startFetchingThumbnails()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this.imageFetcher.stopFetching()
  }

  private startFetchingThumbnails() {
    const entries: {[key: string]: string} = {}
    this.props.inputFeeds.forEach(input => {
      entries[input.id] = input.thumbnailUrl
    })
    this.imageFetcher.startFetchingImagesForKeys(entries)
  }

  onImageFetchedForKey(key: string, imageUrl: string) {
    const copy = Object.assign({}, this.state.imagesByInputFeedId)
    copy[key] = imageUrl
    this.setState({ imagesByInputFeedId: copy })
  }


  private handleResize() {
    this.setState({gridHeight: this.calculateGridHeight(window.innerHeight)})
  }

  private onDragStart(event: any, inputFeedId: string) {
    event.dataTransfer.setData('inputFeedId', inputFeedId)
    //TODO: For IE, use: ev.dataTransfer.setData(“text/plain”, inputId)
  }

  private onDragOver(event: any) {
    // By default, data/elements cannot be dropped in other elements.
    // To allow a drop, we must prevent the default handling of the element.
    event.preventDefault()
  }

  private onDrop(event: any, receivingOutput: Output) {
    //TODO: For IE, use: var id = ev.dataTransfer.getData(“text”)
    const inputFeedId = event.dataTransfer.getData('inputFeedId')
    this.confirmActivateInputRequest(inputFeedId, receivingOutput)
  }

  private confirmActivateInputRequest(inputFeedId: string, receivingOutput: Output) {
    const isAlreadyActivated = receivingOutput.selectedInputId === inputFeedId
    if (isAlreadyActivated) {
      return
    }

    this.setState({
      pageState: PageState.CONFIRM_SELECTION,
      inputFeedToActivate: this.props.inputFeeds.find(i => i.id === inputFeedId),
      outputToUpdate: receivingOutput,
    })
  }

  private confirmDeactivateOutputRequest(receivingOutput: Output) {
    const isAlreadyDeactivated = receivingOutput.selectedInputId === null
    if (isAlreadyDeactivated) {
      return
    }

    this.setState({
      pageState: PageState.CONFIRM_SELECTION,
      inputFeedToActivate: null,
      outputToUpdate: receivingOutput,
    })
  }

  private handleInputSelected(inputFeed: InputFeed) {
    this.setState({
      pageState: PageState.SELECTION_IN_PROGRESS,
      inputFeedToActivate: inputFeed,
      outputToUpdate: undefined
      }
    )
  }

  private renderConfirmSelection() {
    // State Pre-requisites
    const outputToUpdate = this.state.outputToUpdate
    if (this.state.inputFeedToActivate === undefined || outputToUpdate === undefined) {
      return null
    }

    const inputFeedToActivate: InputFeed | null = this.state.inputFeedToActivate
    const isRemovingInputFeedFromOutput = this.state.inputFeedToActivate === null

    const contents = isRemovingInputFeedFromOutput ?
      `Remove ${outputToUpdate.selectedInputId} from ${outputToUpdate.name}?`
      :
      `Show ${inputFeedToActivate!.channelId} on ${outputToUpdate.name}?`

    return (
      <Dialog open onClose={this.resetToDefaultState}>
        <DialogTitle>{`Confirmation required`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { contents }
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.resetToDefaultState} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={() => this.activateInputFeed(inputFeedToActivate, outputToUpdate)} color="secondary" >
            Confirm
          </Button>
        </DialogActions>

      </Dialog>
    )
  }

  private renderSelectionInProgress() {
    // State Pre-requisites
    const outputs = this.props.outputs
    if (this.state.inputFeedToActivate === undefined || outputs.length === 0) {
      return null
    }

    const inputFeedToActivate: InputFeed = this.state.inputFeedToActivate!
    let selectedOutput: Output = this.state.outputToUpdate ? this.state.outputToUpdate : outputs[0]

    return (
      <Dialog open onClose={this.resetToDefaultState}>
        <DialogTitle>{`Select destination for ${inputFeedToActivate.channelId}`}</DialogTitle>
        <DialogContent>
          <RadioGroup
            name='outputChoices'
            value={selectedOutput.id}
            onChange={(event: object, value: string) => { this.setState({outputToUpdate: outputs.find(o => o.id === value)})}}
          >
            { outputs.map(output => (<FormControlLabel key={output.id} value={output.id} label={output.name} control={<Radio />} /> )) }
          </RadioGroup>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.resetToDefaultState} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={() =>  this.activateInputFeed(inputFeedToActivate, selectedOutput)} color="secondary">
            Proceed
          </Button>
        </DialogActions>

      </Dialog>
    )
  }


  private activateInputFeed(inputFeed: InputFeed | null, output: Output) {
    if (this.props.selectedAffiliateId === null) {
      return
    }

    //FIXME: Call OutputAPI.selectInput(affiliateId, outputId, inputFeed) instead of mock api
    new MockOutputApi().selectInput(this.props.selectedAffiliateId, inputFeed, output.id).then(
      () => {
        console.log('Sent successfully!')
        output.selectedInputId = inputFeed === null ? null : inputFeed.id
        this.resetToDefaultState()
      },
      (error: any) => {
        console.log('Failed: ' + error.response + ' ' + error.status + ' ' + error)
        this.resetToDefaultState()
        window.alert(error)
      })
  }

  private viewFeed(inputFeed: InputFeed) {
    this.imageFetcher.stopFetching()
    this.setState({ pageState: PageState.VIEWING_FEED, inputFeedToView: inputFeed})
  }

  private handleViewFeedDismissed() {
    this.resetToDefaultState()
    this.startFetchingThumbnails()
  }

  render() {
    let stateView: any
    switch (this.state.pageState) {
      case PageState.DEFAULT:
        stateView = null
        break

      case PageState.SELECTION_IN_PROGRESS:
        stateView = this.renderSelectionInProgress()
        break

      case PageState.CONFIRM_SELECTION:
        stateView = this.renderConfirmSelection()
        break

      case PageState.VIEWING_FEED:
        stateView = <ViewFeedPage inputFeed={this.state.inputFeedToView!}
                                  onClose={this.handleViewFeedDismissed}/>
        break
    }

    return (
      <Grid container
            direction='row'
            justify='space-between'>

        {/* INPUT GRID BEGIN */}

        <Grid
          item
          //Each grid item will occupy: 9 / 3 (3 items per row) = 3 units. (which is the same as the output grid's grid items)
          xs={9}
          style={{height: this.state.gridHeight}}

          container
          direction='row'
          justify='flex-start'
          alignItems='flex-start'
          alignContent='flex-start'

          className={this.props.classes.inputGrid}>

          <HeaderRow title='Input feeds'/>

          {this.props.inputFeeds.map(inputFeed => {
            const imageUrl = this.state.imagesByInputFeedId[inputFeed.id]
            return (
              <Grid key={inputFeed.id}
                    className={this.props.classes.inputGridItemContainer}
                    item>
                <div
                  className={this.props.classes.gridItemContainerAspectRatio}
                  draggable={true}
                  onDragStart={(e) => this.onDragStart(e, inputFeed.id)}>
                  <div className={this.props.classes.gridItem}>
                    <FeedItem primaryButtonIcon={<ActivateFeedIcon/>}
                              primaryButtonTooltip={'Activate'}
                              onPrimaryButtonClicked={() => this.handleInputSelected(inputFeed)}

                              secondaryButtonIcon={<ViewFeedIcon/>}
                              secondaryButtonTooltip={'View Feed'}
                              onSecondaryButtonClicked={() => this.viewFeed(inputFeed)}

                              imageUrl={imageUrl}
                              titleText={inputFeed.channelId}/>
                  </div>
                </div>
              </Grid>
            )})
          }

        </Grid>
        {/* INPUT GRID END */}



        {/* OUTPUT GRID BEGIN */}
        <Grid item
              //Each grid item will occupy: 3 units. (which is the same as the input grid's grid items)
              xs={3}
              style={{height: this.state.gridHeight}}

              container
              direction='row'
              justify='flex-start'
              alignItems='flex-start'
              alignContent='flex-start'

              className={this.props.classes.outputGrid}>

          <HeaderRow title='Output feeds'/>

          {
            this.props.outputs.map((output, index) => {
              const selectedInput = this.props.inputFeeds.find(i => i.id === output.selectedInputId)
              const imageUrl = output.selectedInputId ? this.state.imagesByInputFeedId[output.selectedInputId] : undefined
              const titleText = `${output.name} - ${selectedInput && selectedInput.channelId ? selectedInput.channelId : 'No feed active'}`

              return (
                <Grid key={index}
                      zeroMinWidth
                      item
                  //The ondragover event specifies where the dragged data can be dropped.
                      onDragOver={this.onDragOver}
                      onDrop={(e) => this.onDrop(e, output)}
                      className={this.props.classes.outputGridItemContainer}>
                  <div className={this.props.classes.gridItemContainerAspectRatio}>
                    <div className={this.props.classes.gridItem}>
                      <FeedItem primaryButtonIcon={selectedInput ? <DeactivateFeedIcon/> : undefined}
                                primaryButtonTooltip={selectedInput ? 'Deactivate' : undefined}
                                onPrimaryButtonClicked={selectedInput ? () => this.confirmDeactivateOutputRequest(output) : undefined }

                                secondaryButtonIcon={selectedInput ? <ViewFeedIcon/> : undefined}
                                secondaryButtonTooltip={selectedInput ? 'View Feed' : undefined}
                                onSecondaryButtonClicked={selectedInput ? () => this.viewFeed(selectedInput) : undefined }
                                imageUrl={imageUrl}
                                titleText={titleText}/>
                    </div>
                  </div>
                </Grid>
              )})
          }

        </Grid>
        {/* OUTPUT GRID END */}

        { stateView }

      </Grid>
    )
  }







}


function mapStateToProps(state: GlobalState) {
  return {
    selectedAffiliateId: state.selectedAffiliateId,
    inputFeeds: InputFeedSelectors.inputFeedsBelongingToAffiliate(state.affiliates.find(a => a.id === state.selectedAffiliateId), state.inputFeeds),
    outputs: OutputSelectors.outputsBelongingToAffiliateId(state.selectedAffiliateId, state.outputsByAffiliateId)
  };
}

const withStyled = withStyles(styles)(ManageFeedsPage)
const connected = connect(mapStateToProps, null)(withStyled)
export default connected
