import { IUserApi, UserApi } from './user/UserApi'
import { MockUserApi } from './user/MockUserApi'
import { MockAffiliateApi } from './affiliate/MockAffiliateApi'
import { IAffiliateApi, AffiliateApi } from './affiliate/AffiliateApi'
import { IOutputApi, OutputApi } from './output/OutputApi'
import { MockOutputApi } from './output/MockOutputApi'
import { IInputApi, InputApi } from './input/InputApi'
import { MockInputApi } from './input/MockInputApi'

export interface IApi {
  userApi: IUserApi
  affiliateApi: IAffiliateApi
  outputApi: IOutputApi
  inputApi: IInputApi
}

/******************     *******************/
/****************** API *******************/
/******************     *******************/

export class Api implements IApi {
  public readonly userApi = new UserApi()
  public readonly affiliateApi = new AffiliateApi()
  public readonly outputApi = new OutputApi()
  public readonly inputApi = new InputApi()
}


/******************      *******************/
/****************** MOCK *******************/
/******************      *******************/

export class MockApi implements IApi {
  public readonly userApi = new MockUserApi()
  public readonly affiliateApi = new MockAffiliateApi()
  public readonly outputApi = new MockOutputApi()
  public readonly inputApi = new MockInputApi()

}