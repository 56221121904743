import React from 'react';
import PaginatedTable, { PaginatedTableRow } from '../common/table/PaginatedTable'
import Paper from '@material-ui/core/Paper'
import { createStyles, Theme, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button/Button'
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import HeaderRow from './header/HeaderRow'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'


const manageListPageStyles = (theme: Theme) => createStyles({
  root: {
    width: '100%'
  },
  createButton: {
    marginRight: theme.spacing.unit * 3.5,
  },
});


interface ManageListTemplateProps extends WithStyles<typeof manageListPageStyles> {
  headerTitle: string
  toolTipText: string
  rowData: PaginatedTableRow[]
  showCreateButton: boolean

  handleCreate(): Promise<void>
  handleSelected(id: string): Promise<void>
  handleDelete(id: string): Promise<void>
}

interface State {
  idToDelete?: string
}

class ManageListTemplatePage extends React.PureComponent<ManageListTemplateProps, State> {
  public constructor(props: ManageListTemplateProps) {
    super(props)
    this.state = {}

    this.promptDelete = this.promptDelete.bind(this)
    this.handleDeleteConfirmed = this.handleDeleteConfirmed.bind(this)
    this.dismissDeletePrompt = this.dismissDeletePrompt.bind(this)
  }

  private promptDelete(idToDelete: string) {
    this.setState({ idToDelete: idToDelete })
  }

  private handleDeleteConfirmed() {
    const deletedId = this.state.idToDelete!
    this.setState({ idToDelete: undefined }, () => this.props.handleDelete(deletedId))
  }

  private dismissDeletePrompt() {
    this.setState({ idToDelete: undefined })
  }

  render() {
    const rowToDelete = this.props.rowData.find(row => row.id === this.state.idToDelete)
    const shouldShowDeletePrompt = rowToDelete !== undefined

    if (shouldShowDeletePrompt) {
      return (
        <Dialog
          open={true}
          onClose={this.dismissDeletePrompt}
        >
          <DialogTitle>{`Really delete '${rowToDelete!.title}' ?`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.dismissDeletePrompt} color="primary" autoFocus>
              Cancel
            </Button>
            <Button onClick={this.handleDeleteConfirmed} color="secondary" >
              Delete
            </Button>
          </DialogActions>

        </Dialog>
      )
    }

    return (
      <React.Fragment >

        <HeaderRow title={this.props.headerTitle}>
          {this.props.showCreateButton &&
          <Tooltip title={this.props.toolTipText} placement={'top'}>
            <Button onClick={this.props.handleCreate}
                    variant="fab"
                    mini
                    aria-label="Add"
                    className={this.props.classes.createButton}>
              <AddIcon/>
            </Button>
          </Tooltip>
          }
        </HeaderRow>

        <Paper className={this.props.classes.root}>
          <PaginatedTable rowData={this.props.rowData}
                          onRowSelected={this.props.handleSelected}
                          onRowDeleted={this.promptDelete}
          />
        </Paper>

      </React.Fragment>
    )
  }
}

const styled = withStyles(manageListPageStyles)(ManageListTemplatePage)
export default styled
