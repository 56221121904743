import User from '../../model/User'
import { IUserApi } from './UserApi'
import { MOCK_DELAY_MS, MOCK_USERS } from '../MockData'


export class MockUserApi implements IUserApi {
  login(username: string, password: string): Promise<User> {
    return new Promise((resolve, reject) =>
      setTimeout(() => {
        const user = MOCK_USERS.find(user => user.username === username)
        if (user) {
          return resolve(user)
        }
        return reject("403 Unauthorized")
      },
        MOCK_DELAY_MS))
  }
}

