import {ActionType, ActionTypeKeys} from "../actions/ActionTypes";
import { initialState } from './RootReducer'
import Affiliate from '../../model/Affiliate'

export default function affiliateReducer(state: Affiliate[] = initialState.affiliates, action: ActionType): Affiliate[] {
  switch (action.type) {

    case ActionTypeKeys.CREATE_AFFILIATE_SUCCESS:
      return [...state, action.affiliate]

    case ActionTypeKeys.UPDATE_AFFILIATE_SUCCESS: {
      const index = state.findIndex(a => a.id === action.affiliate.id)
      if (index === -1) {
        return state
      }

      const copy: Affiliate[] = Object.assign([], state)
      copy[index] = action.affiliate
      return copy
    }

    case ActionTypeKeys.FETCH_AFFILIATES_SUCCESS:
      return action.affiliates;

    case ActionTypeKeys.DELETE_AFFILIATE_SUCCESS:
      return state.filter(affiliate => affiliate.id !== action.affiliateId)

    default:
      return state;
  }
}