import axios from 'axios'

///Demo 2 CH_01 notification key is: 6d303f220b7266952603850bc34f187e

//FIXME: Remove after demo

export class NotificationMessage {
  frontendUrl: string;
  msPlayNotificationUrl: string;
  channelId: string;
  notificationId: number;
  notificationKey: string;
  isSynchronized: boolean;
  isSticky: boolean;
  isClear: boolean;
  isMSPlay: boolean;
  delayMs: number;
  data: string;

  constructor(frontendUrl: string, msPlayNotificationUrl: string, channelId: string, notificationId: number, notificationKey: string, isSynchronized: boolean, isSticky: boolean, isClear: boolean, isMSPlay: boolean, delayMs: number, data: string) {
    this.frontendUrl = frontendUrl;
    this.msPlayNotificationUrl = msPlayNotificationUrl;
    this.channelId = channelId;
    this.notificationId = notificationId;
    this.notificationKey = notificationKey;
    this.isSynchronized = isSynchronized;
    this.isSticky = isSticky;
    this.isClear = isClear;
    this.isMSPlay = isMSPlay;
    this.delayMs = delayMs;
    this.data = data;
  }
}



export default class SyeApi {

  public static async sendNotificationMessage(notification: NotificationMessage): Promise<any> {
    this.allowSelfSignedCertificates();

     if (notification.isMSPlay) {
      return this.sendNotificationMessageToMSPlay(notification)
     }
     return this.sendNotificationMessageToSyeSystem(notification)
  }


  private static allowSelfSignedCertificates() {
    /// Allow self signed certificates. This option should really only be used in development mode
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
  }

  private static async sendNotificationMessageToSyeSystem(notification: NotificationMessage): Promise<any> {
    const path = `${notification.frontendUrl}/channel/${notification.channelId}/notification-message/${notification.notificationId}`;
    const headers = {
      'Content-Type': 'application/json; charset=UTF-8',
      'notification-key': `${notification.notificationKey}`
    };

    let response;
    if (notification.isClear) {
      response = await axios.delete(path, { headers });
      console.log(`Sent delete/clear sye notification towards ${notification.frontendUrl} - response: ${response.status}`);
      if (response.status !== 200) { throw "Failed to clear notification message: Received status" + response.status }
    }
    else {
      const payload = JSON.stringify({sticky: notification.isSticky, synchronized: notification.isSynchronized, delayMs: notification.delayMs, data: notification.data});
      response = await axios.post(path, payload, { headers });
      console.log(`Sent sye notification: ${payload} - response: ${response.status}`);
      if (response.status !== 200) { throw "Failed to send notification message: Received status" + response.status }
    }

    return response
  }


  private static async sendNotificationMessageToMSPlay(notification: NotificationMessage): Promise<any> {
    const channelIndex = await this.getChannelIndexOfChannelFromMSPlay(notification.frontendUrl, notification.channelId);
    const path = `${notification.msPlayNotificationUrl}/metadata`;
    const headers = {
      'Content-Type': 'application/json; charset=UTF-8',
    };

    // const request = JSON.stringify({
    //   "jsonrpc": "2.0",
    //   "method": "metadata",
    //   "id": 1,
    //   "params": {
    //     "channel_id": channelIndex,
    //     "message_id": notification.notificationId,
    //     "sticky": notification.isSticky,
    //     "synchronized": notification.isSynchronized,
    //     "clear": notification.isClear,
    //     "delay_ms": notification.delayMs,
    //     "data": notification.data
    //   }
    // });

    const request = JSON.stringify({
      "channel_id": channelIndex,
      "message_id": notification.notificationId,
      "sticky": notification.isSticky,
      "synchronized": notification.isSynchronized,
      "clear": notification.isClear,
      "delay_ms": notification.delayMs,
      "data": notification.data
    });

    console.log(`Sending msplay notification to ${path}: ${request}`);
    const response = await axios.post(path, request, { headers });
    console.log(`response: ${response.status}`);
    if (response.status !== 200) { throw "Failed to send notification message from MSPlay: Received status" + response.status }
    return response
  }


  private static async getChannelIndexOfChannelFromMSPlay(syeUrl: string, channelId: string) {
    const response = await axios.get(`${syeUrl}/channel`);
    const channelList: any[] = response.data;
    const channelIndex = channelList.findIndex((channelObj) => channelObj.channelId === channelId)

    return channelIndex + 1 //MSPlay starts at index 1
  }
}