import {ActionTypeKeys} from './ActionTypes';

import { Action, Dispatch } from 'redux'
import { GlobalState, ThunkResult } from '../reducers/RootReducer'
import { IApi } from '../../api/Api'
import InputFeed from '../../model/InputFeed'

/* ACTIONS */

export interface FetchInputFeedsSuccessAction extends Action {
  type: ActionTypeKeys.FETCH_INPUT_FEEDS_SUCCESS
  inputFeeds: InputFeed[];
}


/* THUNK ACTION CREATORS */

export function fetchInputFeeds(): ThunkResult<Promise<InputFeed[]>> {
  return (dispatch: Dispatch, getState: () => GlobalState, api: IApi): Promise<InputFeed[]> => {
    return api.inputApi.fetchInputFeeds().then(
      inputFeeds => {
        dispatch({ type: ActionTypeKeys.FETCH_INPUT_FEEDS_SUCCESS, inputFeeds: inputFeeds});
        return inputFeeds
      },
      error => {
        throw error
      })
  };
}
