import {ActionType, ActionTypeKeys} from "../actions/ActionTypes";
import { initialState } from './RootReducer'

export default function selectAffiliateReducer(state: string | null = initialState.selectedAffiliateId, action: ActionType): string| null {
  switch (action.type) {

    case ActionTypeKeys.SELECT_AFFILIATE:
      return action.affiliateId

    default:
      return state;
  }
}