import { defaultParentInset } from '../../../styles'
import React from 'react'
import { PropertyTemplate, PropertyView } from './Property'
import TextField from '@material-ui/core/TextField/TextField'


/** #################################                   ############################# */
/** ################################# PROPERTY TEMPLATE ############################# */
/** #################################                   ############################# */

/**
 * This class represents an alphanumeric property
 */
export class AlphanumericPropertyTemplate implements PropertyTemplate {
  public readonly id: string
  public readonly label: string

  constructor(id: string, label: string) {
    this.id = id
    this.label = label
  }

  /** Whether or not the input field element should be marked as required */
  public isRequired?: boolean
  public autoFocus?: boolean
  public isDisabled?: boolean
  public placeholder?: string
  public helperText?: string

  /** Optional. Initial value of the this property */
  public defaultValue?: string

  /** Optional. If present, will fail input validation if this function returns false */
  public inputValidator?: (value?: string) => boolean

}



/** #################################               ############################# */
/** ################################# PROPERTY VIEW ############################# */
/** #################################               ############################# */


interface Props {
  template: AlphanumericPropertyTemplate

  /// Called when an input event has occurred, i.e. when input has been updated
  onInputUpdated(): void
}
interface State {
  inputValue: string
}

export class AlphaNumericPropertyView extends React.PureComponent<Props, State> implements PropertyView {
  constructor(props: Props) {
    super(props)
    this.state = {
      inputValue: this.props.template.defaultValue ? this.props.template.defaultValue : ""
    }

    this.getInputValue = this.getInputValue.bind(this)
    this.hasBeenModified = this.hasBeenModified.bind(this)
    this.isInvalidInput = this.isInvalidInput.bind(this)
    this.handleInputEvent = this.handleInputEvent.bind(this)

  }

  public getInputValue(): [string, any] {
    return [this.props.template.id, this.state.inputValue]
  }

  public hasBeenModified(): boolean {
    return this.state.inputValue !== this.props.template.defaultValue
  }

  public isInvalidInput(): boolean {
    if (this.props.template.inputValidator) {
      return this.props.template.inputValidator(this.state.inputValue) === false
    }
    return false
  }

  private handleInputEvent(event: any) {
    /// Copy event.target.value in a local variable since we need to access it asynchronously in setState
    const newValue = event.target.value
    this.setState({inputValue: newValue}, () => {
      this.props.onInputUpdated()
    })
  }

  render() {
    return <TextField
      value={this.state.inputValue}
      onChange={this.handleInputEvent}
      error={this.isInvalidInput()}

      style={{ marginLeft: defaultParentInset, paddingRight: defaultParentInset * 2 }}
      label={this.props.template.label}
      placeholder={this.props.template.placeholder}
      helperText={this.props.template.helperText}
      required={this.props.template.isRequired}
      disabled={this.props.template.isDisabled}
      autoFocus={this.props.template.autoFocus}
      fullWidth
      margin="normal"
    />
  }
}

