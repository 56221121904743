import InputFeed from '../../model/InputFeed'

export interface IInputApi {
  fetchInputFeeds(): Promise<InputFeed[]>
}

export class InputApi implements IInputApi {
  fetchInputFeeds(): Promise<InputFeed[]> {
    throw "Not implemented"
  }
}