import React from 'react';
import withAuthorization, { AuthorizedProps } from '../common/hoc/AuthorizedComponent'
import { AuthorizationLevel } from '../../model/User'
import { PaginatedTableRow } from '../common/table/PaginatedTable'
import { connect } from 'react-redux'
import * as affiliateActions from '../../redux/actions/AffiliateActions'
import { GlobalState, ThunkDispatch } from '../../redux/reducers/RootReducer'
import ManageListTemplatePage from '../common/ManageListTemplatePage'
import ManageAffiliatePage from './ManageAffiliatePage'
import { RoutePath } from '../../routes'
import Affiliate from '../../model/Affiliate'
import { Route, Switch } from 'react-router'
import { RouteComponentProps, withRouter } from 'react-router-dom'

type GlobalStateProps = ReturnType<typeof mapStateToProps>
type IDispatchProps = ReturnType<typeof mapDispatchToProps>

interface Props extends GlobalStateProps, IDispatchProps, AuthorizedProps, RouteComponentProps<any> {}

interface State {
  affiliateToUpdate?: Affiliate
}

class ListAffiliatesPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}

    this.getRowData = this.getRowData.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleSelected = this.handleSelected.bind(this)
    this.handleDelete = this.handleDelete.bind(this)

    this.handleAffiliateSaved = this.handleAffiliateSaved.bind(this)
    this.handleAffiliateCreated = this.handleAffiliateCreated.bind(this)
    this.handleAffiliateUpdated = this.handleAffiliateUpdated.bind(this)
  }

  getRowData(): PaginatedTableRow[] {
    return this.props.affiliates.map((affiliate) => ({
      id: affiliate.id,
      title: affiliate.name,
      isDeletable: this.props.authorizationLevel === AuthorizationLevel.CREATE_READ_WRITE_DELETE
    }))
  }

  handleCreate(): Promise<void> {
    this.props.history.push(RoutePath.AFFILIATE_CREATE)
    return Promise.resolve()
  }

  handleSelected(id: string): Promise<void> {
    this.setState({affiliateToUpdate: this.props.affiliates.find(a => a.id === id)}, () => {
      this.props.history.push(RoutePath.AFFILIATE_UPDATE)
    })
    return Promise.resolve()
  }

  handleDelete(id: string): Promise<void> {
    this.props.deleteAffiliate(id)
    return Promise.resolve()
  }



  handleAffiliateSaved(wasUpdated: boolean, affiliate: Affiliate) {
    console.log("On affiliate saved: " + JSON.stringify(affiliate))
    if (wasUpdated) {
      this.handleAffiliateUpdated(affiliate)
    } else {
      this.handleAffiliateCreated(affiliate)
    }
  }

  handleAffiliateCreated(affiliate: Affiliate) {
    //FIXME: Show spinner?
    this.props.createAffiliate(affiliate).then(
      created => {
        //TODO: Check if we can remove the pop the last entry instead of replacing it
        // Pop history back to list page...
        this.props.history.replace(RoutePath.AFFILIATES)
      },
      error => {}
    )
  }

  handleAffiliateUpdated(affiliate: Affiliate) {
    const affiliateStillExists = this.props.affiliates.find(a => a.id === affiliate.id) !== undefined
    if (affiliateStillExists === false) {
      console.log("Attempting to update a nonexisting affiliate. id: " + affiliate.id)
      //FIXME: Handle scenario when user has navigated to update page, pressed back, deleted the affiliate, pressed forward, and tapped "UPDATE"
      return
    }

    this.props.updateAffiliate(affiliate).then(
      updated => {
        //TODO: Check if we can remove the pop the last entry instead of replacing it
        // Pop history back to list page...
        this.props.history.replace(RoutePath.AFFILIATES)
      },
      error => {}
    )
  }

  render() {
    const shouldShowCreateButton = this.props.authorizationLevel === AuthorizationLevel.CREATE_READ_WRITE_DELETE
    return (
      <Switch>
        <Route path={RoutePath.AFFILIATE_CREATE} render={props => <ManageAffiliatePage onAffiliateSaved={this.handleAffiliateSaved}
                                                                                       inputFeeds={this.props.inputFeeds} /> } />
        <Route path={RoutePath.AFFILIATE_UPDATE} render={props => <ManageAffiliatePage affiliateToUpdate={this.state.affiliateToUpdate!}
                                                                                       onAffiliateSaved={this.handleAffiliateSaved}
                                                                                       inputFeeds={this.props.inputFeeds} /> } />
        <Route render={ props => <ManageListTemplatePage headerTitle='Affiliates'
                                                         toolTipText='Create new affiliate'
                                                         rowData={this.getRowData()}
                                                         handleCreate={this.handleCreate}
                                                         handleSelected={this.handleSelected}
                                                         handleDelete={this.handleDelete}
                                                         showCreateButton={shouldShowCreateButton}/> }
        />
      </Switch>
    )
  }
}

function mapStateToProps(state: GlobalState) {
  return {
    affiliates: state.affiliates,
    inputFeeds: state.inputFeeds
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    createAffiliate: (affiliate: Affiliate) => dispatch(affiliateActions.createAffiliate(affiliate)),
    updateAffiliate: (affiliate: Affiliate) => dispatch(affiliateActions.updateAffiliate(affiliate)),
    deleteAffiliate: (affiliateId: string) => dispatch(affiliateActions.deleteAffiliate(affiliateId))
  }
}

const withAuthorized = withAuthorization(ListAffiliatesPage)
const connected = connect(mapStateToProps, mapDispatchToProps)(withAuthorized)
export default withRouter(connected)