import React from 'react';
import CreateUpdateTemplatePage, {
  InputValueDictionary,
} from '../common/property-template/CreateUpdateTemplatePage'
import withAuthorization, { AuthorizedProps } from '../common/hoc/AuthorizedComponent'
import { AuthorizationLevel } from '../../model/User'
import Affiliate from '../../model/Affiliate'
import { AlphanumericPropertyTemplate } from '../common/property-template/AlphaNumericProperty'
import { ListEntry, ListSelectionPropertyTemplate } from '../common/property-template/ListSelectionProperty'
import { PropertyTemplate } from '../common/property-template/Property'
import InputFeed from '../../model/InputFeed'


interface Props extends AuthorizedProps {
  /**
   * If not undefined: enter UPDATE mode and populate all text fields according to affiliate
   * Else enter CREATE mode and leave inputs blank
   * */
  affiliateToUpdate?: Affiliate

  inputFeeds: InputFeed[]

  onAffiliateSaved(wasUpdated: boolean, affiliate: Affiliate): void
}
interface State {}

const PROPERTY_ID_AFFILIATE_NAME = "name"
const PROPERTY_ID_AFFILIATE_ID = "id"
const PROPERTY_ID_AFFILIATE_INPUTS = "input-feeds"

class ManageAffiliatePage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
    this.handleItemSaved = this.handleItemSaved.bind(this)
    this.isInUpdateMode = this.isInUpdateMode.bind(this)
  }

  private handleItemSaved(inputValuesById: InputValueDictionary) {
    const isUpdating = this.isInUpdateMode()
    const affiliate: Affiliate = isUpdating ? this.props.affiliateToUpdate! : {id: "", name: "", outputIds: [], inputFeedIds: []}
    affiliate.name = inputValuesById[PROPERTY_ID_AFFILIATE_NAME]
    affiliate.inputFeedIds = inputValuesById[PROPERTY_ID_AFFILIATE_INPUTS]
    this.props.onAffiliateSaved(isUpdating, affiliate)
  }

  private isInUpdateMode(): boolean {
    return this.props.affiliateToUpdate !== undefined
  }


  render() {
    const isUpdatingExistingAffiliate = this.isInUpdateMode()
    const isUserAuthorizedToSaveOrUpdate = isUpdatingExistingAffiliate ?
      this.props.authorizationLevel >= AuthorizationLevel.READ_WRITE :
      this.props.authorizationLevel >= AuthorizationLevel.CREATE_READ_WRITE_DELETE

    const propertiesToShow: PropertyTemplate[] = []

    if (isUpdatingExistingAffiliate) {
      const idProperty = new AlphanumericPropertyTemplate(PROPERTY_ID_AFFILIATE_ID, "Affiliate Id")
      idProperty.isDisabled = true
      idProperty.helperText = "Read Only"
      idProperty.defaultValue = this.props.affiliateToUpdate ? this.props.affiliateToUpdate.id : ""
      propertiesToShow.push (idProperty)
    }

    const nameProperty = new AlphanumericPropertyTemplate(PROPERTY_ID_AFFILIATE_NAME, "Affiliate Name")
    nameProperty.isRequired = true
    nameProperty.isDisabled = isUserAuthorizedToSaveOrUpdate === false
    nameProperty.autoFocus = true
    nameProperty.placeholder = "Affiliate Name"
    nameProperty.defaultValue = this.props.affiliateToUpdate ? this.props.affiliateToUpdate.name : undefined
    nameProperty.inputValidator = (input?: string) => { return input !== undefined && input.trim().length > 0 }
    propertiesToShow.push(nameProperty)


    const inputFeedEntries: ListEntry[] = this.props.inputFeeds.map(inputFeed => ({
      id: inputFeed.id,
      title: inputFeed.channelId,
      subtitle: inputFeed.syeBaseUrl,
      isDisabled: isUserAuthorizedToSaveOrUpdate === false
    }))
    const inputFeedsProperty = new ListSelectionPropertyTemplate(
      PROPERTY_ID_AFFILIATE_INPUTS,
      "Input Feeds",
      inputFeedEntries,
      this.props.affiliateToUpdate ? this.props.affiliateToUpdate.inputFeedIds : []
    )
    propertiesToShow.push(inputFeedsProperty)


    //TODO: Add possibility to view/assign Output ids

    return <CreateUpdateTemplatePage
      showSaveButton={isUserAuthorizedToSaveOrUpdate}
      headerTitle={isUpdatingExistingAffiliate ? 'Edit Affiliate' : 'Create Affiliate'}
      saveButtonTitle={isUpdatingExistingAffiliate ? 'UPDATE' : 'SAVE'}
      onSave={this.handleItemSaved}
      properties={propertiesToShow}
    />
  }
}

export default withAuthorization(ManageAffiliatePage)