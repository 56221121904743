import {ActionType, ActionTypeKeys} from "../actions/ActionTypes";
import { initialState } from './RootReducer'
import InputFeed from '../../model/InputFeed'


export type InputFeedReducerState = InputFeed[]

export default function inputFeedReducer(state: InputFeedReducerState = initialState.inputFeeds, action: ActionType): InputFeedReducerState {
  switch (action.type) {

    case ActionTypeKeys.FETCH_INPUT_FEEDS_SUCCESS: {
      return action.inputFeeds
    }

    default:
      return state;
  }
}