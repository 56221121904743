import * as React from 'react';
import * as ReactDom from 'react-dom';
import { Provider } from 'react-redux'
import { App } from './components/app/App'
import configureStore from './redux/store/ConfigureStore';
import {initialState} from "./redux/reducers/RootReducer";
import { applicationTheme } from './styles'

//MuiThemeProvider makes the theme available down the React tree thanks to React context
import { MuiThemeProvider } from '@material-ui/core/styles';
//CssBaseline provides an elegant, consistent, and simple baseline to build upon.
import CssBaseline from '@material-ui/core/CssBaseline';

const store = configureStore(initialState);

const render = (Component: any, container: any, store: any) => {
  ReactDom.render(
    <Provider store={store}>
      <MuiThemeProvider theme={applicationTheme}>
        <CssBaseline>
          <Component />
        </CssBaseline>
      </MuiThemeProvider>
    </Provider>,

    container
  );
}


const rootElement = document.getElementById('app')
render(App, rootElement, store)


const anyWindow = (window as any)
const syeClientModule = anyWindow['SyeClient'];
const apiConfig = new syeClientModule.SyeAPIConfig()
syeClientModule.Setup(apiConfig).then(
  () => { console.log("Setting up Sye SDK succeeded") },
  () => { console.log("Setting up Sye SDK failed") }
)