import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { createStyles, WithStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'


const styles = (theme: Theme) => createStyles({
    layout: {
      position: 'relative', //Make position relative so that 'progressBox.position' is relative to parent instead of window
      width: 'auto',
      display: 'block', // Fix IE11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
      margin: theme.spacing.unit,
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: '100%', // Fix IE11 issue.
      marginTop: theme.spacing.unit,
    },
    submit: {
      marginTop: theme.spacing.unit * 3,
    },
    progressBox: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }
  }
);


interface Props extends WithStyles<typeof styles> {
  isLoading: boolean
  errorMessage: string

  onLoginSubmitted(username: string, password: string): void
}

interface State {
  username?: string
  password?: string
}

class LoginFormView extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}

    this.onUsernameUpdated = this.onUsernameUpdated.bind(this)
    this.onPasswordUpdated = this.onPasswordUpdated.bind(this)
    this.isValidUsername = this.isValidUsername.bind(this)
    this.isValidPassword = this.isValidPassword.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }


  onUsernameUpdated(event: any) {
    this.setState({username: event.target.value})
  }

  onPasswordUpdated(event: any) {
    this.setState({password: event.target.value})
  }

  onSubmit(event: React.FormEvent) {
    event.preventDefault()

    if (this.isValidUsername(this.state.username) && this.isValidPassword(this.state.password)) {
      this.props.onLoginSubmitted(this.state.username!, this.state.password!)
    }
  }

  isValidUsername(username?: string): boolean {
    return username !== undefined && username.length > 0
  }

  isValidPassword(password?: string): boolean {
    return password !== undefined && password.length > 0
  }


  render() {
    const hasEnteredUsername = this.state.username !== undefined
    const hasEnteredPassword = this.state.password !== undefined
    const isBadUsername = this.isValidUsername(this.state.username) === false
    const isBadPassword = this.isValidPassword(this.state.password) === false

    return (
      <React.Fragment>
        <div className={this.props.classes.layout}>
          <Paper className={this.props.classes.paper}>
            <Avatar className={this.props.classes.avatar}>
              <LockIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            <Typography component="h6" variant="subtitle2" color="error">
              {this.props.errorMessage}
            </Typography>

            <form className={this.props.classes.form}>
              <FormControl margin="normal"
                           required
                           fullWidth
                           error={hasEnteredUsername && isBadUsername}
                           onChange={this.onUsernameUpdated}>
                <InputLabel htmlFor="username">Username</InputLabel>
                <Input id="username" name="username" autoComplete="username" autoFocus />
              </FormControl>

              <div className={this.props.classes.progressBox}>
              { this.props.isLoading && <CircularProgress/> }
              </div>

              <FormControl margin="normal"
                           required
                           fullWidth
                           error={hasEnteredPassword && isBadPassword}
                           onChange={this.onPasswordUpdated}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input name="password" type="password" id="password" autoComplete="current-password"/>
              </FormControl>

              <Button
                type="submit"
                disabled={this.props.isLoading || isBadUsername || isBadPassword}
                onClick={this.onSubmit}
                fullWidth
                variant="contained"
                color="primary"
                className={this.props.classes.submit}>
                Sign in
              </Button>
            </form>
          </Paper>
        </div>
      </React.Fragment>
    );
  }
}


/** Decorate LoginPage with 'withStyles(styles)' so it gets a special 'classes' prop injected */
export default withStyles(styles)(LoginFormView)
