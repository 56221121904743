import React from 'react';
import Paper from '@material-ui/core/Paper'
import { createStyles, Theme, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import HeaderRow from '../header/HeaderRow'
import { defaultParentInset } from '../../../styles'
import Button from '@material-ui/core/Button/Button'
import SaveIcon from '@material-ui/icons/Save';
import { Prompt } from 'react-router'
import { PropertyTemplate, PropertyView } from './Property'
import { AlphaNumericPropertyView, AlphanumericPropertyTemplate } from './AlphaNumericProperty'
import { ListSelectionPropertyTemplate, ListSelectionPropertyView } from './ListSelectionProperty'


const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    minHeight: 555
  },
});


export interface InputValueDictionary {
  /** Key inputId, value == user input */
  [inputId: string] : any
}

export interface CreateUpdateTemplateProps extends WithStyles<typeof styles> {
  showSaveButton: boolean
  headerTitle: string
  saveButtonTitle: string

  properties: PropertyTemplate[]

  onSave: (savedProperties: InputValueDictionary) => void
}

interface State {
  isDirtyState: boolean
  isInvalidInput: boolean
}

class CreateUpdateTemplatePage extends React.PureComponent<CreateUpdateTemplateProps, State> {
  private readonly inputElementRefs: PropertyView[] = []
  private readonly inputElementJSX: React.ComponentType[] = []

  constructor(props: CreateUpdateTemplateProps) {
    super(props)

    this.state = {
      isDirtyState: false,
      isInvalidInput: false
    }

    this.createJSXFromPropertyTemplates = this.createJSXFromPropertyTemplates.bind(this)
    this.handleInputUpdated = this.handleInputUpdated.bind(this)
    this.handleSaveButtonClicked = this.handleSaveButtonClicked.bind(this)

    this.inputElementJSX = this.createJSXFromPropertyTemplates(this.props.properties)

  }

  private createJSXFromPropertyTemplates(templates: PropertyTemplate[]): React.ComponentType[] {
    return (templates.map(property => {
      if (property instanceof AlphanumericPropertyTemplate) {
        return <AlphaNumericPropertyView key={property.id}
                                         ref={ (ref) => { if (ref !== null) { this.inputElementRefs.push(ref) } }}
                                         template={property}
                                         onInputUpdated={this.handleInputUpdated} />
      }
      if (property instanceof ListSelectionPropertyTemplate) {
        return <ListSelectionPropertyView key={property.id}
                                          ref={ (ref) => { if (ref !== null) { this.inputElementRefs.push(ref) } }}
                                          template={property}
                                          onInputUpdated={this.handleInputUpdated}
        />

      }
    }).filter(element => element !== undefined) as any)
  }

  private handleInputUpdated() {
    const isDirtyState = this.inputElementRefs.reduce( (accumulator: boolean, element: PropertyView): boolean => { return accumulator || element.hasBeenModified() }, false)
    const containsInvalidInput = this.inputElementRefs.reduce( (accumulator: boolean, element: PropertyView): boolean => { return accumulator || element.isInvalidInput() }, false)

    this.setState({
      isDirtyState: isDirtyState,
      isInvalidInput: containsInvalidInput
    })
  }

  private handleSaveButtonClicked() {
    this.setState({isDirtyState: false}, () => {
      const inputValuesByPropertyId: InputValueDictionary = {}
      this.inputElementRefs.forEach(element => {
        const entry = element.getInputValue()
        inputValuesByPropertyId[entry[0]] = entry[1]
      })

      this.props.onSave(inputValuesByPropertyId)
    })
  }


  render() {
    return (
      <React.Fragment>

        <HeaderRow title={this.props.headerTitle}>
          { this.props.showSaveButton &&
          <Button onClick={this.handleSaveButtonClicked}
                  variant="contained"
                  disabled={this.state.isDirtyState === false || this.state.isInvalidInput}
                  style={{ marginRight: defaultParentInset}}>
            <SaveIcon style={{ marginRight: 8}}/>
            {this.props.saveButtonTitle}
          </Button>
          }
        </HeaderRow>

        <Paper className={this.props.classes.root}>
          {this.inputElementJSX}
        </Paper>

        <Prompt
          when={this.state.isDirtyState}
          message={location =>  `You have unsaved changes. Are you sure you want to leave this page?` }
        />

      </React.Fragment>
    )
  }
}

const styled = withStyles(styles)(CreateUpdateTemplatePage)
export default styled
