import {ActionType, ActionTypeKeys} from "../actions/ActionTypes";
import { initialState } from './RootReducer'
import User from '../../model/User'

type State = User

export default function userReducer(state: State = initialState.user, action: ActionType): State {
  switch (action.type) {

    case ActionTypeKeys.FETCH_USER_SUCCESS:
      return action.user;

    default:
      return state;
  }
}