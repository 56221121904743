import React from 'react';
import CreateUpdateTemplatePage, {
  InputValueDictionary,
} from '../common/property-template/CreateUpdateTemplatePage'
import Output from '../../model/Output'
import withAuthorization, { AuthorizedProps } from '../common/hoc/AuthorizedComponent'
import { AuthorizationLevel } from '../../model/User'
import { AlphanumericPropertyTemplate } from '../common/property-template/AlphaNumericProperty'
import { PropertyTemplate } from '../common/property-template/Property'


const PROPERTY_ID_OUTPUT_NAME = "name"
const PROPERTY_ID_OUTPUT_ID = "id"
const PROPERTY_ID_SELECTED_INPUT_ID = "selected-input-id"

interface Props extends AuthorizedProps {
  /**
   * If not undefined: enter UPDATE mode and populate all text fields according to output
   * Else enter CREATE mode and leave inputs blank
   * */
  outputToUpdate?: Output

  onOutputSaved(wasUpdated: boolean, output: Output): void
}
interface State {}

class ManageOutputPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}

    this.handleItemSaved = this.handleItemSaved.bind(this)
    this.isInUpdateMode = this.isInUpdateMode.bind(this)
  }

  private handleItemSaved(inputValuesById: InputValueDictionary) {
    const isUpdating = this.isInUpdateMode()
    const output: Output = isUpdating ? this.props.outputToUpdate! : {id: "", name: "", selectedInputId: null}
    output.name = inputValuesById[PROPERTY_ID_OUTPUT_NAME]
    this.props.onOutputSaved(isUpdating, output)
  }

  private isInUpdateMode(): boolean {
    return this.props.outputToUpdate !== undefined
  }


  render() {
    const isUpdatingExistingOutput = this.isInUpdateMode()
    const isUserAuthorizedToSaveOrUpdate = isUpdatingExistingOutput ?
    this.props.authorizationLevel >= AuthorizationLevel.READ_WRITE :
    this.props.authorizationLevel >= AuthorizationLevel.CREATE_READ_WRITE_DELETE

    const propertiesToShow: PropertyTemplate[] = []
    if (isUpdatingExistingOutput) {
      const idProperty = new AlphanumericPropertyTemplate(PROPERTY_ID_OUTPUT_ID, "Output Id")
      idProperty.isDisabled = true
      idProperty.helperText = "Read Only"
      idProperty.defaultValue = this.props.outputToUpdate ? this.props.outputToUpdate.id : ""
      propertiesToShow.push(idProperty)
    }

    const nameProperty = new AlphanumericPropertyTemplate(PROPERTY_ID_OUTPUT_NAME, "Output Name")
    nameProperty.isRequired = true
    nameProperty.isDisabled = isUserAuthorizedToSaveOrUpdate === false
    nameProperty.autoFocus = true
    nameProperty.placeholder = "Output Name"
    nameProperty.helperText = "A user friendly name of the output device"
    nameProperty.defaultValue = this.props.outputToUpdate ? this.props.outputToUpdate.name : undefined
    nameProperty.inputValidator = (value?: string) => { return value !== undefined && value.trim().length > 0 }
    propertiesToShow.push(nameProperty)

    const selectedInputIdProperty = new AlphanumericPropertyTemplate(PROPERTY_ID_SELECTED_INPUT_ID, "Selected input id")
    selectedInputIdProperty.isDisabled = true
    selectedInputIdProperty.helperText = "Read Only"
    selectedInputIdProperty.defaultValue = (this.props.outputToUpdate === undefined || this.props.outputToUpdate.selectedInputId === null) ?
      'No input is selected' :
      this.props.outputToUpdate.selectedInputId
    propertiesToShow.push(selectedInputIdProperty)

    return <CreateUpdateTemplatePage
      showSaveButton={isUserAuthorizedToSaveOrUpdate}
      headerTitle={isUpdatingExistingOutput ? 'Edit Output' : 'Create Output'}
      saveButtonTitle={isUpdatingExistingOutput ? 'UPDATE' : 'SAVE'}
      onSave={this.handleItemSaved}
      properties={propertiesToShow}
    />
  }
}

export default withAuthorization(ManageOutputPage)