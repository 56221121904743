import User from "../../model/User";
import {ActionTypeKeys} from './ActionTypes';

import { Action, Dispatch } from 'redux'
import { GlobalState, ThunkResult } from '../reducers/RootReducer'
import { IApi } from '../../api/Api'


/* ACTIONS */

export interface FetchUserSuccessAction extends Action {
  type: ActionTypeKeys.FETCH_USER_SUCCESS
  user: User;
}

/* THUNK ACTION CREATORS https://github.com/reduxjs/redux/issues/1676 */

// If you use Redux Thunk...
// You can define asynchronous action creators that return functions - we call such action creators "thunks".
//A thunk is a function that wraps an expression to delay its evaluation.
export function fetchUser(username: string, password: string): ThunkResult<Promise<User>> {
  // Redux Thunk will inject dispatch here:
  return (dispatch: Dispatch<FetchUserSuccessAction>, getState: () => GlobalState, extraArgument: IApi): Promise<User> => {
    // Perform the actual API call
    return extraArgument.userApi.login(username, password).then(
      user => {
        // Send an action that reducers may handle, to e.g. show the data
        dispatch({ type: ActionTypeKeys.FETCH_USER_SUCCESS, user: user});
        // Resolve promise
        return user
      },
      error => {
        // Rethrow so returned Promise is rejected
        throw error
      })
  };
}