import Output from '../../model/Output'
import InputFeed from '../../model/InputFeed'

export interface IOutputApi {
  createOutput(affiliateId: string, output: Output): Promise<Output>
  updateOutput(affiliateId: string, output: Output): Promise<Output>
  fetchOutputsForAffiliate(affiliateId: string): Promise<Output[]>
  deleteOutput(affiliateId: string, outputId: string): Promise<void>

  selectInput(affiliateId: string, inputFeed: InputFeed | null, outputId: string): Promise<void>;
}

export class OutputApi implements IOutputApi {

  createOutput(affiliateId: string, output: Output): Promise<Output> {
    throw "Not implemented"
  }

  updateOutput(affiliateId: string, output: Output): Promise<Output> {
    throw "Not implemented"
  }

  fetchOutputsForAffiliate(affiliateId: string): Promise<Output[]> {
    throw "Not implemented"
  }

  deleteOutput(affiliateId: string, outputId: string): Promise<void> {
    throw "Not implemented"
  }

  selectInput(affiliateId: string, inputFeed: InputFeed | null, outputId: string): Promise<void> {
    throw "Not implemented"
  }
}