import React from 'react'
import CrossfadeImage from '../common/CrossfadeImage'
import Card from '@material-ui/core/Card/Card'
import { createStyles, Theme, WithStyles } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import CardActions from '@material-ui/core/CardActions/CardActions'
import Typography from '@material-ui/core/Typography/Typography'
import CardContent from '@material-ui/core/CardContent/CardContent'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Icon, { IconProps } from '@material-ui/core/Icon/Icon'



const styles = (theme: Theme) => createStyles({
    fillParent: {
      width: '100%',
      height: '100%',
      padding: 0,
      margin: 0
    },
    body: {
      width: '100%',
      height: '80%',
      padding: 0,
      margin: 0,
    },
    footer: {
      width: '100%',
      height: '20%',
      display: 'flex',
      justifyContent: 'space-between'
    },
  }
);


interface Props extends WithStyles<typeof styles> {
  primaryButtonIcon?: React.ReactElement<IconProps>
  primaryButtonTooltip?: string
  onPrimaryButtonClicked?: () => void

  secondaryButtonIcon?: React.ReactElement<IconProps>
  secondaryButtonTooltip?: string
  onSecondaryButtonClicked?: () => void

  imageUrl?: string
  titleText: string
}

interface State {}

class FeedItem extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
  }

  render() {
    return (
      <Card className={this.props.classes.fillParent}>

        <CardContent className={this.props.classes.body}>
          <CrossfadeImage style={{width: "100%", height: '100%', margin: 0, padding: 0}}
                          src={this.props.imageUrl}
                          alt={this.props.titleText}
          />
        </CardContent>

        <CardActions className={this.props.classes.footer}>

          <Typography component='h1' variant='subtitle2' noWrap>
            {this.props.titleText}
          </Typography>


          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            { this.props.primaryButtonIcon &&
            <Tooltip style={{margin: 2, padding: 2}} title={this.props.primaryButtonTooltip} placement={'top'}>
              <IconButton onClick={this.props.onPrimaryButtonClicked}>
                {this.props.primaryButtonIcon}
              </IconButton>
            </Tooltip>
            }
            {
              this.props.secondaryButtonIcon &&
              <Tooltip style={{margin: 2, padding: 0}} title={this.props.secondaryButtonTooltip} placement={'top'}>
                <IconButton onClick={this.props.onSecondaryButtonClicked}>
                  {this.props.secondaryButtonIcon}
                </IconButton>
              </Tooltip>
            }
          </div>

        </CardActions>
      </Card>
    )
  }
}

export default withStyles(styles)(FeedItem)