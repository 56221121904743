import {ActionTypeKeys} from './ActionTypes';

import { Action, Dispatch } from 'redux'
import { GlobalState, ThunkResult } from '../reducers/RootReducer'
import { IApi } from '../../api/Api'
import Output from '../../model/Output'


/* ACTIONS */

export interface CreateOutputSuccessAction extends Action {
  type: ActionTypeKeys.CREATE_OUTPUT_SUCCESS
  affiliateId: string,
  output: Output;
}

export interface UpdateOutputSuccessAction extends Action {
  type: ActionTypeKeys.UPDATE_OUTPUT_SUCCESS
  affiliateId: string,
  output: Output;
}

export interface FetchOutputsSuccessAction extends Action {
  type: ActionTypeKeys.FETCH_OUTPUTS_SUCCESS
  affiliateId: string,
  outputs: Output[];
}

export interface DeleteOutputSuccessAction extends Action {
  type: ActionTypeKeys.DELETE_OUTPUT_SUCCESS
  affiliateId: string,
  outputId: string;
}

export interface DeleteAffiliateOutputsAction extends Action {
  type: ActionTypeKeys.DELETE_AFFILIATE_OUTPUTS
  affiliateId: string,
}

/* THUNK ACTION CREATORS */

export function createOutput(affiliateId: string, output: Output): ThunkResult<Promise<Output>> {
  return (dispatch: Dispatch<CreateOutputSuccessAction>, getState: () => GlobalState, api: IApi): Promise<Output> => {
    return api.outputApi.createOutput(affiliateId, output).then(
      created => {
        dispatch({type: ActionTypeKeys.CREATE_OUTPUT_SUCCESS, affiliateId: affiliateId, output: created})
        return created
      },
      error => {
        throw error
      })
  };
}

export function updateOutput(affiliateId: string, output: Output): ThunkResult<Promise<Output>> {
  return (dispatch: Dispatch<UpdateOutputSuccessAction>, getState: () => GlobalState, api: IApi): Promise<Output> => {
    return api.outputApi.updateOutput(affiliateId, output).then(
      updated => {
        dispatch({type: ActionTypeKeys.UPDATE_OUTPUT_SUCCESS, affiliateId: affiliateId, output: updated})
        return updated
      },
      error => {
        throw error
      })
  };
}

export function fetchOutputsForAffiliate(affiliateId: string): ThunkResult<Promise<Output[]>> {
  return (dispatch: Dispatch<FetchOutputsSuccessAction>, getState: () => GlobalState, api: IApi): Promise<Output[]> => {
    return api.outputApi.fetchOutputsForAffiliate(affiliateId).then(
      outputs => {
        dispatch({ type: ActionTypeKeys.FETCH_OUTPUTS_SUCCESS, affiliateId: affiliateId, outputs: outputs});
        return outputs
      },
      error => {
        throw error
      })
  };
}

export function deleteOutput(affiliateId: string, outputId: string): ThunkResult<Promise<void>> {
  return (dispatch: Dispatch<DeleteOutputSuccessAction>, getState: () => GlobalState, api: IApi): Promise<void> => {
    return api.outputApi.deleteOutput(affiliateId, outputId).then(
      () => {
        dispatch({type: ActionTypeKeys.DELETE_OUTPUT_SUCCESS, affiliateId: affiliateId, outputId: outputId})
      },
      error => {
        throw error
      })
  };
}