import { AnyAction, combineReducers } from 'redux'
import { ThunkAction, ThunkDispatch as ReduxThunkDispatch } from 'redux-thunk';
import userReducer from './UserReducer'
import affiliateReducer from './AffiliateReducer'
import inputFeedReducer from './InputFeedReducer'
import selectAffiliateReducer from './SelectAffiliateReducer'
import outputReducer from './OutputReducer'
import { IApi } from '../../api/Api'
import { AuthorizationLevel } from '../../model/User'



/** Important: Export 'initialState' BEFORE creating any reducers since they use it **/

/*
The redux store stores all state and the views/components select/present a slice of it.

State structure:
 {
   "user": {},

   "affiliates" : [ {id: "a1", name: "Mock Affiliate", outputIds: ["o1", "o2", ...] }, ... ],

   "inputFeeds": [ {id: "i1", channelId: "CH_01", thumbnailUrl: "https://...", syeBaseUrl: "https://...", username: "sye", password: "ott"} ],

   "outputsByAffiliateId" : {
       "a1" : [ {id: "o1", name: "Output 1"}, {id: "o2", name: "Output 2"}, ... ],
       "a2" : [],
       }
   },

 "selectedAffiliateId": "a1",
 }
 */
export const initialState: GlobalState = {
  user: {username: "Logged out user", authorizationLevel: AuthorizationLevel.UNAUTHORIZED},
  affiliates: [],
  inputFeeds: [],
  selectedAffiliateId: null,
  outputsByAffiliateId: {},
};

const reducerMap = {
  user: userReducer,
  affiliates: affiliateReducer,
  inputFeeds: inputFeedReducer,
  outputsByAffiliateId: outputReducer,
  selectedAffiliateId: selectAffiliateReducer
}
export const rootReducer = combineReducers(reducerMap);

export type GlobalState = {
  [K in keyof typeof reducerMap]: ReturnType<typeof reducerMap[K]>
}




/**
 * https://gist.github.com/milankorsos/ffb9d32755db0304545f92b11f0e4beb
 *
 * A ThunkResult is utility type representing a pre-parameterized ThunkAction.
 * All parameters are prepopulated except the generic parameter 'R', which is the return type of the ThunkResult.
 *
 */
export type ThunkResult<R> = ThunkAction<R, GlobalState, IApi, AnyAction>

/**
 * ThunkDispatch is a utility type used in mapDispatchToProps.
 * It accepts a 'ThunkAction'/'ThunkResult' and returns a type of the ThunkActions generic 'R' parameter
 *
 * Usage example:
 * const mapDispatchToProps = (dispatch: ThunkDispatch) => {
 * return {
 *   fetchUser: (username: string, password: string) => dispatch(userActions.fetchUser(username, password))
 * }
 *
 * ReduxThunkDispatch is parameterized with the following interfaces (each type will be passed to every thunk action creator):
 * - S: Redux State interface (GlobalState in our case)
 * - E: ExtraArgument: Any extra arguments passed when calling 'redux.applyMiddleware(thunk.withExtraArgument...)'.
 * - A: Action: The action type being passed to the action creator
 */
export type ThunkDispatch = ReduxThunkDispatch<GlobalState, IApi, AnyAction>

