import React from 'react';
import withAuthorization, { AuthorizedProps } from '../common/hoc/AuthorizedComponent'
import { AuthorizationLevel } from '../../model/User'
import { PaginatedTableRow } from '../common/table/PaginatedTable'
import { connect } from 'react-redux'
import * as outputActions from '../../redux/actions/OutputActions'
import { GlobalState, ThunkDispatch } from '../../redux/reducers/RootReducer'
import ManageListTemplatePage  from '../common/ManageListTemplatePage'
import { OutputSelectors } from '../../redux/selectors/OutputSelectors'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import ManageOutputPage from './ManageOutputPage'
import Output from '../../model/Output'
import { RoutePath } from '../../routes'

type GlobalStateProps = ReturnType<typeof mapStateToProps>
type IDispatchProps = ReturnType<typeof mapDispatchToProps>

interface Props extends GlobalStateProps, IDispatchProps, AuthorizedProps, RouteComponentProps<any> {}
interface State {
  outputToUpdate?: Output
}

class ListOutputsPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}

    this.getRowData = this.getRowData.bind(this)
    this.handleCreate = this.handleCreate.bind(this)
    this.handleSelected = this.handleSelected.bind(this)
    this.handleDelete = this.handleDelete.bind(this)

    this.handleOutputSaved = this.handleOutputSaved.bind(this)
    this.handleOutputCreated = this.handleOutputCreated.bind(this)
    this.handleOutputUpdated = this.handleOutputUpdated.bind(this)
  }

  getRowData(): PaginatedTableRow[] {
    return this.props.outputs.map((output) => ({
      id: output.id,
      title: output.name,
      isDeletable: this.props.authorizationLevel === AuthorizationLevel.CREATE_READ_WRITE_DELETE
    }))
  }

  handleCreate(): Promise<void> {
    this.props.history.push(RoutePath.OUTPUT_CREATE)
    return Promise.resolve()
  }

  handleSelected(id: string): Promise<void> {
    this.setState({outputToUpdate: this.props.outputs.find(o => o.id === id)}, () => {
      this.props.history.push(RoutePath.OUTPUT_UPDATE)
    })
    return Promise.resolve()
  }

  handleDelete(id: string): Promise<void> {
    if (this.props.selectedAffiliateId !== null) {
      this.props.deleteOutput(this.props.selectedAffiliateId, id)
    }
    return Promise.resolve()
  }


  handleOutputSaved(wasUpdated: boolean, output: Output) {
    if (wasUpdated) {
      this.handleOutputUpdated(output)
    } else {
      this.handleOutputCreated(output)
    }
  }

  handleOutputCreated(output: Output) {
    if (this.props.selectedAffiliateId === null) {
      return
    }

    //FIXME: Show spinner?
    this.props.createOutput(this.props.selectedAffiliateId, output).then(
      created => {
        //TODO: Check if we can remove the pop the last entry instead of replacing it
        // Pop history back to list page...
        this.props.history.replace(RoutePath.OUTPUTS)
      },
      error => {}
    )
  }

  handleOutputUpdated(output: Output) {
    if (this.props.selectedAffiliateId === null) {
      return
    }

    const outputStillExists = this.props.outputs.find(o => o.id === output.id) !== undefined
    if (outputStillExists === false) {
      console.log("Attempting to update a nonexisting output. id: " + output.id)
      //FIXME: Handle scenario when user has navigated to update page, pressed back, deleted the output, pressed forward, and tapped "UPDATE"
      return
    }

    this.props.updateOutput(this.props.selectedAffiliateId, output).then(
      updated => {
        //TODO: Check if we can remove the pop the last entry instead of replacing it
        // Pop history back to list page...
        this.props.history.replace(RoutePath.OUTPUTS)
      },
      error => {}
    )
  }

  render() {
    const shouldShowCreateButton = this.props.authorizationLevel === AuthorizationLevel.CREATE_READ_WRITE_DELETE && this.props.selectedAffiliateId !== null
    return (
      <Switch>
        <Route path={RoutePath.OUTPUT_CREATE} render={props => <ManageOutputPage onOutputSaved={this.handleOutputSaved} /> } />
        <Route path={RoutePath.OUTPUT_UPDATE} render={props => <ManageOutputPage onOutputSaved={this.handleOutputSaved}
                                                                                 outputToUpdate={this.state.outputToUpdate!} /> } />
        <Route render={ props => <ManageListTemplatePage headerTitle='Outputs'
                                                         toolTipText='Create new output'
                                                         rowData={this.getRowData()}
                                                         handleCreate={this.handleCreate}
                                                         handleSelected={this.handleSelected}
                                                         handleDelete={this.handleDelete}
                                                         showCreateButton={shouldShowCreateButton}/> }
        />
      </Switch>
    )
  }
}

function mapStateToProps(state: GlobalState) {

  return {
    selectedAffiliateId: state.selectedAffiliateId,
    outputs: OutputSelectors.outputsBelongingToAffiliateId(state.selectedAffiliateId, state.outputsByAffiliateId)
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    createOutput: (affiliateId: string, output: Output) => dispatch(outputActions.createOutput(affiliateId, output)),
    updateOutput: (affiliateId: string, output: Output) => dispatch(outputActions.updateOutput(affiliateId, output)),
    deleteOutput: (affiliateId: string, outputId: string) => dispatch(outputActions.deleteOutput(affiliateId, outputId))
  }
}

const withAuthorized = withAuthorization(ListOutputsPage)
const connected = connect(mapStateToProps, mapDispatchToProps)(withAuthorized)
export default withRouter(connected)