import React from 'react'
import {
  createStyles, Divider, Drawer,
  List, ListItem, ListItemIcon, Theme,
  withStyles, ListItemText, WithStyles
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Typography from '@material-ui/core/Typography/Typography'
import { TopLevelRoute } from '../../../routes/index'
import { NavLink } from 'react-router-dom'
import { drawerWidth, transition, defaultFontProperties } from '../../../styles'
import Hidden from '@material-ui/core/Hidden/Hidden'
import AppNavigationBarLinks from './AppNavigationBarLinks'
import Affiliate from '../../../model/Affiliate'
import nimbraEdgeLogo from '../../../img/netinsight-logo.svg'
import cingularityLogo from '../../../img/CingularityLight.png'

const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
};


const styles = (theme: Theme) => createStyles({
  //Styles applied to the Drawer's 'Paper' component.
  drawerPaper: {
    border: "none",
    position: 'fixed',
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: theme.zIndex.drawer,
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%"
    },

    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
      ...boxShadow,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "auto",
      zIndex: theme.zIndex.drawer,
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    }
  },

  list: {
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "20px",
    listStyle: "none",
    position: "unset"
  },

  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",

    //Color of the animation
    "&:hover,&:focus,&:visited,&": {
      color: theme.palette.action.selected
    }
  },

  itemLink: {
    width: "auto",
    transition: "all 300ms linear",
    margin: "0",
    borderRadius: "0px",
    position: "relative",
    display: "block",
    padding: "10px 15px",
    backgroundColor: "transparent",
    fontFamily: theme.typography.fontFamily,
    ...defaultFontProperties
  },

  activeItemLink: {
    backgroundColor: theme.palette.action.selected,
    // boxShadow: `0 12px 20px -10px ${theme.palette.type === 'light' ? theme.palette.grey.A100 : theme.palette.grey.A400}, 0 4px 20px 0 ${theme.palette.type === 'light' ? theme.palette.grey.A200 : theme.palette.grey.A400}, 0 7px 8px -5px ${theme.palette.type === 'light' ? theme.palette.grey.A100 : theme.palette.grey.A400}`,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      //boxShadow: `0 12px 20px -10px ${theme.palette.grey.A400}, 0 4px 20px 0 ${theme.palette.grey.A400}, 0 7px 8px -5px ${theme.palette.grey.A400}`,
    }
  },

  itemIcon: {
    width: "24px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: theme.palette.text.primary
  },

  itemText: {
    fontFamily: theme.typography.fontFamily,
    ...defaultFontProperties,
    margin: "0",
    lineHeight: "30px",
    fontSize: "14px",
    color: theme.palette.text.primary
  },

  toolbar: {
    ...theme.mixins.toolbar,

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  icons: {
    width: "auto",
    height: "30px",
    margin: "0px 8px 0px 16px",

    [theme.breakpoints.up("md")]: {
      margin: "0px 8px 0px 24px",
    },
  },

  logo: {
    width: "50%",
    margin: "0 auto"
  }
});


interface Props extends WithStyles<typeof styles>{
  ///The current route
  activePath: string

  ///The currently logged in user
  username: string

  affiliates: Affiliate[]
  selectedAffiliateIndex: number
  onAffiliateSelected: (index: number) => void

  navLinks: TopLevelRoute[]
  open: boolean
  onSideBarClosed: () => void
}

interface State {}

class Sidebar extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
    this.createList = this.createList.bind(this)
  }

  private createList(classes: any, routes: TopLevelRoute[]) {
    return (
      <List className={classes.list}>
        {routes.map((route: TopLevelRoute, key) => {
          const isActive = this.props.activePath === route.path
          if(route.disabled){
            return (<ListItem button className={`${classes.itemLink} ${isActive ? classes.activeItemLink : ''}`} disabled={route.disabled} key={key}>
                  <ListItemIcon className={classes.itemIcon}>
                    <route.icon/>
                  </ListItemIcon>
                  <ListItemText
                    primary={route.sidebarName}
                    className={classes.itemText}
                    disableTypography={true}
                  />
                </ListItem>);
          } else {
            return (
                <NavLink
                className={classes.item}
                to={route.path}
                activeClassName="active"
                key={key}>
                <ListItem button className={`${classes.itemLink} ${isActive ? classes.activeItemLink : ''}`}>
                  <ListItemIcon className={classes.itemIcon}>
                    <route.icon/>
                  </ListItemIcon>
                  <ListItemText
                    primary={route.sidebarName}
                    className={classes.itemText}
                    disableTypography={true}
                  />
                </ListItem>
                </NavLink>
            );
          }
        })}
      </List>
    );
  }


  render() {
    const list = this.createList(this.props.classes, this.props.navLinks)
    return (
      <React.Fragment>

        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor="right"
            open={this.props.open}
            onClose={this.props.onSideBarClosed}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            classes={{
              paper: this.props.classes.drawerPaper //Styles applied to the Drawer's 'Paper' component.
            }}>
            {this.props.username !== 'danpope' &&
              <div className={this.props.classes.toolbar}><img src={nimbraEdgeLogo} className={this.props.classes.logo} /></div>
            }
            {this.props.username === 'danpope' &&
              <div className={this.props.classes.toolbar}><img src={cingularityLogo} className={this.props.classes.logo} /></div>
            }
            <Divider />
            <AppNavigationBarLinks affiliates={this.props.affiliates}
                         selectedAffiliateIndex={this.props.selectedAffiliateIndex}
                         onAffiliateSelected={this.props.onAffiliateSelected}
            />
            {list}
            <Divider />
            <div className={this.props.classes.toolbar}>
              <AccountCircle color='action' className={this.props.classes.icons} />
              <Typography>{this.props.username}</Typography>
            </div>
          </Drawer>
        </Hidden>

        <Hidden smDown>
          <Drawer
            variant="permanent"
            anchor="left"
            open
            classes={{
              paper: this.props.classes.drawerPaper //Styles applied to the Drawer's 'Paper' component.
            }}>

            {this.props.username !== 'danpope' &&
              <div className={this.props.classes.toolbar}><img src={nimbraEdgeLogo} className={this.props.classes.logo} /></div>
            }
            {this.props.username === 'danpope' &&
              <div className={this.props.classes.toolbar}><img src={cingularityLogo} style={{width: "75%"}} className={this.props.classes.logo} /></div>
            }
            <Divider />
            {list}
            <Divider />
            <div className={this.props.classes.toolbar}>
              <AccountCircle color='action' className={this.props.classes.icons} />
              <Typography>{this.props.username}</Typography>
            </div>
          </Drawer>
        </Hidden>

      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Sidebar)