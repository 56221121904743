import {ActionType, ActionTypeKeys} from "../actions/ActionTypes";
import { initialState } from './RootReducer'
import Output from '../../model/Output'


export interface OutputReducerState { [affiliateId: string] : Output[] }

export default function outputReducer(state: OutputReducerState = initialState.outputsByAffiliateId, action: ActionType): OutputReducerState {
  switch (action.type) {

    case ActionTypeKeys.CREATE_OUTPUT_SUCCESS: {
      const copy = Object.assign({}, state)
      const existingOutputs = copy[action.affiliateId] ? copy[action.affiliateId] : []
      copy[action.affiliateId] = [...existingOutputs, action.output]
      return copy
    }

    case ActionTypeKeys.UPDATE_OUTPUT_SUCCESS: {
      const existingOutputs = state[action.affiliateId] ? state[action.affiliateId] : []
      const oldOutputIndex = existingOutputs.findIndex(o => o.id === action.output.id)
      if (oldOutputIndex > -1) {
        existingOutputs[oldOutputIndex] = action.output
      }
      state[action.affiliateId] = Object.assign([], existingOutputs)
      return state
    }

    case ActionTypeKeys.FETCH_OUTPUTS_SUCCESS: {
      const copy = Object.assign({}, state)
      copy[action.affiliateId] = action.outputs
      return copy
    }

    case ActionTypeKeys.DELETE_OUTPUT_SUCCESS: {
      const copy = Object.assign({}, state)
      copy[action.affiliateId] = copy[action.affiliateId].filter(output => output.id !== action.outputId)
      return copy
    }

    case ActionTypeKeys.DELETE_AFFILIATE_OUTPUTS: {
      const copy = Object.assign({}, state)
      delete copy[action.affiliateId]
      return copy
    }


    default:
      return state;
  }
}