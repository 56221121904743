import { SyeNotificationMessage, SyeVideoTrack, SyePlayerState, ISyePlayer } from '../../lib/sye/types'

const SyeClient = require('../../lib/sye/syeClient');
import { EventEmitter, EventSubscription } from 'fbemitter'

export class SyeConnectionDetails {
  public readonly baseURL: string;
  public readonly channelId: string;
  public readonly presentationDelayMs: number;
  public readonly username: string;
  public readonly password: string;

  constructor(baseURL: string, channelId: string, presentationDelayMs: number, username: string, password: string) {
    this.baseURL = baseURL;
    this.channelId = channelId;
    this.presentationDelayMs = presentationDelayMs;
    this.username = username;
    this.password = password;
  }

  public getCredentialsString() {
    return `{"username":"${this.username}", "password":"${this.password ? this.password : ''}"}`
  }
}

export declare interface PlayerCallbackEventEmitter {
  addListener(eventType: 'onError', listener: (aCode: number, aDescription: string) => void): EventSubscription
  addListener(eventType: 'onErrorRetry', listener: (aCode: number, aDescription: string, aRetryAfterMillis: number) => void): EventSubscription
  addListener(eventType: 'onVideoTrackChange', listener: (aFrom?: SyeVideoTrack, aTo?: SyeVideoTrack) => void): EventSubscription
  addListener(eventType: 'onNotificationMessage', listener: (aNotificationMessage: SyeNotificationMessage) => void): EventSubscription
}

export class PlayerManager {
  private readonly mPlayer: ISyePlayer
  private readonly mEventEmitter = new EventEmitter();
  private readonly mAttachedSurfaces: { [identifer: string] : HTMLVideoElement } = {}

  /************************* PUBLIC **********************/

  public constructor(connectionDetails: SyeConnectionDetails) {
    this.play = this.play.bind(this);
    this.stop = this.stop.bind(this);
    this.setAudioVolume = this.setAudioVolume.bind(this)
    this.attachVideoSurface = this.attachVideoSurface.bind(this)
    this.detachVideoSurface = this.detachVideoSurface.bind(this)
    this.getEventEmitter = this.getEventEmitter.bind(this)

    this.onError = this.onError.bind(this)
    this.onErrorRetry = this.onErrorRetry.bind(this)
    this.onVideoTrackChange = this.onVideoTrackChange.bind(this)
    this.onNotificationMessage = this.onNotificationMessage.bind(this)

    this.createPlayerTowards = this.createPlayerTowards.bind(this)
    this.createSyeSystem = this.createSyeSystem.bind(this)

    this.mPlayer = this.createPlayerTowards(connectionDetails.baseURL, connectionDetails.getCredentialsString())
  }

  public play(channelId: string) {
    this.mPlayer.playFromLive(channelId)
  }

  public stop(): void {
    this.mPlayer.stop()
  }

  public setAudioVolume(volume: number) {
    this.mPlayer.audioVolume(volume)
  }

  public attachVideoSurface(videoSurface: HTMLVideoElement, identifier: string) {
    const alreadyAttachedView = this.mAttachedSurfaces[identifier]
    if (alreadyAttachedView) {
      this.detachVideoSurface(identifier)
    }
    this.mAttachedSurfaces[identifier] = videoSurface
    this.mPlayer.attachView(videoSurface)
  }

  public detachVideoSurface(identifier: string) {
    const attachedView = this.mAttachedSurfaces[identifier]
    if (attachedView) {
      this.mPlayer.detachView(attachedView)
      delete this.mAttachedSurfaces[identifier]
    }
  }

  public getEventEmitter(): PlayerCallbackEventEmitter {
    return this.mEventEmitter
  }

  /************************* Callbacks **********************/

  private onError(aCode: number, aDescription: string) {
    this.mEventEmitter.emit('onError',  aCode, aDescription)
  }

  private onErrorRetry(aCode: number, aDescription: string, aRetryAfterMillis: number) {
    this.mEventEmitter.emit('onErrorRetry',  aCode, aDescription, aRetryAfterMillis)

    setTimeout(() => {
      const player = this.mPlayer
      if (player && player.playerState() == SyePlayerState.kError) {
        console.log('Restarting sye.');
        player.playResume();
      } else {
        console.log('Skipping retry since player is no longer in an error state');
      }
    }, aRetryAfterMillis)
  }

  private onVideoTrackChange(aFrom?: SyeVideoTrack, aTo?: SyeVideoTrack) {
    this.mEventEmitter.emit('onVideoTrackChange',  aFrom, aTo)
  }

  private onNotificationMessage(aNotificationMessage: SyeNotificationMessage) {
    this.mEventEmitter.emit('onNotificationMessage', aNotificationMessage)
  }


  /************************* PRIVATE **********************/

  private createPlayerTowards(baseURL: string, credentials: string): ISyePlayer {
    const syeSystem = this.createSyeSystem(baseURL, credentials)
    const player = SyeClient.CreatePlayer(syeSystem, null, this.onError, this.onErrorRetry)
    if (!player) {
      throw TypeError('CreatePlayer(): Browser not supported, User-Agent: ' + navigator.userAgent);
    }

    player.onNotificationMessageCallback = this.onNotificationMessage
    player.onVideoTrackChangeCallback = this.onVideoTrackChange

    return player;
  }

  /**
   * @brief Utility function to create a SyeSystem.
   */
  private createSyeSystem(aBaseUrl: string, aCredentials: string) {
    let lSyeFrontend = new SyeClient.SyeFrontend();
    lSyeFrontend.baseUrl = aBaseUrl;
    lSyeFrontend.credentials = aCredentials

    let lSyeSystem = new SyeClient.SyeSystem();
    lSyeSystem.syeFrontends.push(lSyeFrontend);
    return lSyeSystem
  }
}