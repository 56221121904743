import Redux, { applyMiddleware, compose, createStore } from 'redux'
import thunk from "redux-thunk";
import {rootReducer, GlobalState} from "../reducers/RootReducer";
import { IApi, MockApi, Api } from '../../api/Api'

export default function configureStore(initialState: GlobalState): Redux.Store {
  const api: IApi = process.env.USE_MOCK === "true" ? new MockApi() : new Api()

  const middleWare = applyMiddleware(
    thunk.withExtraArgument(api)
  )
  //TODO: Clean up redux devtools
  const devWindow = (window as any)
  const isChrome = !!devWindow.chrome;
  const isDevtoolsAvailable = isChrome && devWindow.__REDUX_DEVTOOLS_EXTENSION__ !== undefined

  const composedMiddleware =
    isDevtoolsAvailable ?
      compose(
        middleWare,
        devWindow.__REDUX_DEVTOOLS_EXTENSION__()
      ) :
      middleWare

  return createStore(
    rootReducer,
    initialState,
    composedMiddleware
  );
}
