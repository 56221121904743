import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { createStyles, Theme, WithStyles } from '@material-ui/core'

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  listItem: {
    padding: "0 10px",
  }
});

interface Props extends WithStyles<typeof styles>{
  title: string
  options: string[]
  selectedIndex: number

  onSelect: (index: number) => void
}

interface State {
  anchorElement: any
}

class SimpleListMenu extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      anchorElement: null,
    }

    this.handleClickListItem = this.handleClickListItem.bind(this)
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  private handleClickListItem(event: any) {
    this.setState({ anchorElement: event.currentTarget });
  };

  private handleMenuItemClick(event: any, index: number) {
    this.setState({ anchorElement: null }, () => {
      this.props.onSelect(index)
    });
  };

  private handleClose() {
    this.setState({ anchorElement: null });
  };

  render() {
    const anchorEl = this.state.anchorElement;

    return (
      <div className={this.props.classes.root}>
        <List component="nav">
          <ListItem className={this.props.classes.listItem}
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            aria-label="Affiliate"
            onClick={this.handleClickListItem}>
            <ListItemText
              primary={this.props.title}
              primaryTypographyProps={{noWrap: true}}
              secondary={this.props.options[this.props.selectedIndex]}
              secondaryTypographyProps={{noWrap: true}}
            />
          </ListItem>
        </List>

        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {this.props.options.map((option, index) => (
            <MenuItem
              key={option}
              disabled={index === this.props.selectedIndex}
              selected={index === this.props.selectedIndex}
              onClick={event => this.handleMenuItemClick(event, index)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(SimpleListMenu);
