import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { createStyles, Theme, WithStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip/Tooltip'

export interface PaginatedTableRow {
  id: string
  title: string
  isDeletable: boolean
}


const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableRow: {
    height: 48,
  },
  tableRowContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    height: '100%',
    margin: 0
  },
});


interface Props extends WithStyles<typeof styles>{
  rowData: PaginatedTableRow[]

  onRowSelected(rowId: string): void
  onRowDeleted(rowId: string): void
}
interface State {
  currentPage: number
  rowsPerPage: number
}

class PaginatedTable extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      currentPage: 0,
      rowsPerPage: 10,
    };

    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
  }

  private handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, page: number) {
    this.setState({ currentPage: page });
  };

  private handleChangeRowsPerPage(event: any) {
    this.setState({ rowsPerPage:  event.target.value });
  };

  render() {
    const emptyRows =
      this.state.rowsPerPage -
      Math.min(
        this.state.rowsPerPage,
        this.props.rowData.length - this.state.currentPage * this.state.rowsPerPage
      );

    return (

      <div className={this.props.classes.tableWrapper}>
        <Table className={this.props.classes.table}>
          <TableBody>
            {
              this.props.rowData.slice(this.state.currentPage * this.state.rowsPerPage,
                this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage).map(row => {
                return (
                  <TableRow className={this.props.classes.tableRow}
                            key={row.id}
                            hover={true}
                  >
                    <TableCell scope="row">
                      <div className={this.props.classes.tableRowContent}>

                        <div onClick={() => this.props.onRowSelected(row.id)}
                             style={{cursor: 'pointer', width: row.isDeletable ? '90%' : '100%', height: '100%'}}>
                        {row.title}
                        </div>

                        { row.isDeletable &&
                        <div>
                          <Tooltip title={`Delete ${row.title}`} placement={'top'}>
                            <IconButton className={this.props.classes.button}
                                        aria-label="Delete"
                                        onClick={() => this.props.onRowDeleted(row.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                        }
                      </div>
                      </TableCell>

                  </TableRow>
                );
              })
            }

            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell/>
              </TableRow>
            )}

          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                count={this.props.rowData.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.currentPage}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(PaginatedTable);