import { defaultParentInset } from '../../../styles'
import React from 'react'
import { PropertyTemplate, PropertyView } from './Property'
import List from '@material-ui/core/List/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import ListSubheader from '@material-ui/core/ListSubheader/ListSubheader'
import Typography from '@material-ui/core/Typography/Typography'


/** #################################                   ############################# */
/** ################################# PROPERTY TEMPLATE ############################# */
/** #################################                   ############################# */

export interface ListEntry {
  id: string
  title: string
  subtitle: string
  isDisabled? : boolean
}
export class ListSelectionPropertyTemplate implements PropertyTemplate {
  public readonly id: string
  public readonly title: string

  /** All available entries, i.e. the entire data source */
  public readonly entries: ListEntry[]

  /** The ids of the pre-selected entries */
  public readonly preSelectedEntryIds: string[]

  /** If present: will fail input validation if this function returns false */
  public inputValidator?: (selectedEntryIds: string[]) => boolean

  constructor(id: string, title: string, values: ListEntry[], preSelectedEntryIds: string[]) {
    this.id = id
    this.title = title
    this.entries = values
    this.preSelectedEntryIds = preSelectedEntryIds
  }
}


/** #################################               ############################# */
/** ################################# PROPERTY VIEW ############################# */
/** #################################               ############################# */


interface Props {
  template: ListSelectionPropertyTemplate

  /// Called when an input event has occurred, i.e. when input has been updated
  onInputUpdated(): void
}
interface State {
  selectedIds: string[]
}

export class ListSelectionPropertyView extends React.PureComponent<Props, State> implements PropertyView {
  constructor(props: Props) {
    super(props)
    this.state = {
      selectedIds: this.props.template.preSelectedEntryIds
    }

    this.getInputValue = this.getInputValue.bind(this)
    this.hasBeenModified = this.hasBeenModified.bind(this)
    this.isInvalidInput = this.isInvalidInput.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
  }

  public getInputValue(): [string, any] {
    return [this.props.template.id, this.state.selectedIds]
  }

  public hasBeenModified(): boolean {
    const areSameSize = this.state.selectedIds.length === this.props.template.preSelectedEntryIds.length
    if (areSameSize === false) {
      return true
    }

    const containSameElements = this.state.selectedIds.reduce((acc, selectedElement, index) => {
        return acc && this.props.template.preSelectedEntryIds.find(elem => elem == selectedElement) !== undefined
      }, true)
    return containSameElements === false
  }

  public isInvalidInput(): boolean {
    if (this.props.template.inputValidator) {
      return this.props.template.inputValidator(this.state.selectedIds) === false
    }
    return false
  }

  private handleToggle(toggledId: string) {
    const updatedSelections = Object.assign([], this.state.selectedIds)
    const existingIndex = updatedSelections.findIndex(id => toggledId == id)
    if (existingIndex === -1) {
      updatedSelections.push(toggledId)
    } else {
      updatedSelections.splice(existingIndex, 1)
    }

    this.setState({selectedIds: updatedSelections}, () => {
      this.props.onInputUpdated()
    })
  }

  render() {
    const headerColor = this.isInvalidInput() ? 'error' : 'default'
    const listHeader = <ListSubheader><Typography color={headerColor}>{this.props.template.title}</Typography></ListSubheader>
    const listItems = this.props.template.entries.map(entry => {
      const isDisabled = entry.isDisabled ? entry.isDisabled : false
      return (<ListItem key={entry.id}
                        role={undefined}
                        disabled={isDisabled}
                        dense={true}
                        button={true}
                        onClick={(e: any) => this.handleToggle(entry.id)}>
          <Checkbox
            checked={this.state.selectedIds.indexOf(entry.id) !== -1}
            disabled={isDisabled}
            tabIndex={-1} // tabindex -1 makes 'natural tabbing behaviour' when navigating using the TAB button
            disableRipple
          />
          <ListItemText primary={entry.title}
                        primaryTypographyProps={{color: headerColor}}
                        secondary={entry.subtitle}
          />
        </ListItem>
      )})

    return <List style={{marginTop: 12}} subheader={listHeader}> { listItems } </List>
  }
}

