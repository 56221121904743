import React from 'react'
import ManageFeedsPage from "../components/feeds/ManageFeedsPage";
import { AppRoute } from './index'
import { AuthorizationLevel } from '../model/User'
import ListAffiliatesPage from '../components/affiliate/ListAffiliatesPage'
import ListOutputsPage from '../components/outputs/ListOutputsPage'

import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import GroupIcon from '@material-ui/icons/Group';
import InputIcon from '@material-ui/icons/Input';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PersonIcon from '@material-ui/icons/Person';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

export interface AppRoute {
  path: string // e.g. "/dashboard",

  /// Name presented in navigation bar, e.g. "Material Dashboard",
  navbarName: string

  /// Any nested child routes
  subRoutes: AppRoute[]
}

/// A "Top Level Route", i.e. a route presented in the Side bar
export interface TopLevelRoute extends AppRoute {
  /// Name presented in side drawer, e.g. "Dashboard",
  sidebarName: string

  /// Icon presented in side drawer
  icon: React.ComponentType

  /// Associated component
  component: React.ComponentType

  /// The authorization level required of the user to view/access this route in the Side Bar
  authorizationLevel: AuthorizationLevel

  /// sets the attribute disabled on menu
  disabled: boolean
}

export function getTopLevelRoutes(requiredAuthorizationLevel: number): TopLevelRoute[] {
  return appRoutes.filter(route => route.authorizationLevel <= requiredAuthorizationLevel)
}

export function getRouteMatchingPath(path: string): AppRoute | undefined {
  return recursiveRouteMatchingPath(path, appRoutes)
}

function recursiveRouteMatchingPath(path: string, routes: AppRoute[]): AppRoute | undefined {
  for (const route of routes) {
    if (route.path === path) { return route }
    const matchingSubroute = recursiveRouteMatchingPath(path, route.subRoutes)
    if (matchingSubroute !== undefined) { return matchingSubroute }
  }
  return undefined
}

export enum RoutePath {
  OVERVIEW = '/overview',

  MANAGE = '/manage',

  AFFILIATES = '/affiliates',
  AFFILIATE_CREATE = '/affiliates/create',
  AFFILIATE_UPDATE = '/affiliates/update',

  GROUPS = '/groups',
  GROUP_CREATE = '/groups/create',
  GROUP_UPDATE = '/groups/update',

  OUTPUTS = '/outputs',
  OUTPUT_CREATE = '/outputs/create',
  OUTPUT_UPDATE = '/outputs/update',

  INPUTS = '/inputs',
  INPUT_CREATE = '/inputs/create',
  INPUT_UPDATE = '/inputs/update',
}

const appRoutes: TopLevelRoute[] = [
  {
    path: RoutePath.OVERVIEW,
    component: ManageFeedsPage,
    authorizationLevel: AuthorizationLevel.READ,
    sidebarName: "Stream switcher",
    navbarName: "Switch Feeds",
    icon: ControlCameraIcon,
    subRoutes: [],
    disabled: false
  },
  {
    path: RoutePath.MANAGE,
    component: ManageFeedsPage,
    authorizationLevel: AuthorizationLevel.READ,
    sidebarName: "Stream manager",
    navbarName: "Manage feeds",
    disabled: true,
    icon: ViewModuleIcon,
    subRoutes: [],
  },
  {
    path: RoutePath.AFFILIATES,
    component: ListAffiliatesPage,
    authorizationLevel: AuthorizationLevel.READ,
    sidebarName: "Affiliates",
    navbarName: "Manage Affiliates",
    icon: PersonIcon,
    disabled: false,
    subRoutes: [
      {
        path: RoutePath.AFFILIATE_CREATE,
        navbarName: "Manage Affiliates",
        subRoutes: [],
      },
      {
        path: RoutePath.AFFILIATE_UPDATE,
        navbarName: "Manage Affiliates",
        subRoutes: [],
      },
    ],
  },
  {
    path: RoutePath.GROUPS,
    component: ManageFeedsPage,
    authorizationLevel: AuthorizationLevel.READ,
    sidebarName: "Affiliate groups",
    navbarName: "Affiliate groups",
    disabled: true,
    icon: GroupIcon,
    subRoutes: [],
  },
  {
    path: RoutePath.INPUTS,
    component: ListAffiliatesPage,
    authorizationLevel: AuthorizationLevel.READ,
    sidebarName: "Inputs",
    navbarName: "Manage inputs",
    icon: InputIcon,
    disabled: true,
    subRoutes: [
      {
        path: RoutePath.INPUT_CREATE,
        navbarName: "Manage inputs",
        subRoutes: [],
      },
      {
        path: RoutePath.INPUT_UPDATE,
        navbarName: "Manage inputs",
        subRoutes: [],
      },
    ],
  },
  {
    path: RoutePath.OUTPUTS,
    component: ListOutputsPage,
    authorizationLevel: AuthorizationLevel.READ,
    sidebarName: "Outputs",
    navbarName: "Manage Outputs",
    icon: PresentToAllIcon,
    disabled: false,
    subRoutes: [
      {
        path: RoutePath.OUTPUT_CREATE,
        navbarName: "Manage Outputs",
        subRoutes: [],
      },
      {
        path: RoutePath.OUTPUT_UPDATE,
        navbarName: "Manage Outputs",
        subRoutes: [],
      },
    ],
  },
];
