import Output from '../../model/Output'
import { IOutputApi } from './OutputApi'
import SyeApi, { NotificationMessage } from '../SyeApi'
import { MOCK_AFFILIATES, MOCK_DELAY_MS, MOCK_OUTPUTS } from '../MockData'
import InputFeed from '../../model/InputFeed'


export class MockOutputApi implements IOutputApi {

  createOutput(affiliateId: string, output: Output): Promise<Output> {
    const affiliate = MOCK_AFFILIATES.find(a => a.id === affiliateId)
    if (affiliate === undefined) {
      throw {error: 404, message: `No affiliate with id '${affiliateId}'`}
    }

    const createdOutput: Output = Object.assign({}, output)
    createdOutput.id = createdOutput.name

    // Add newly created output to list of all outputs
    MOCK_OUTPUTS.push(createdOutput)

    // Register output with affiliate
    if (affiliate.outputIds) {
      affiliate.outputIds.push(createdOutput.id)
    } else {
      affiliate.outputIds = [createdOutput.id]
    }

    return new Promise((resolve) => setTimeout(() => resolve(Object.assign({}, createdOutput)), MOCK_DELAY_MS))
  }

  updateOutput(affiliateId: string, output: Output): Promise<Output> {
    const affiliate = MOCK_AFFILIATES.find(a => a.id === affiliateId)
    if (affiliate === undefined) {
      throw {error: 404, message: `No affiliate with id '${affiliateId}'`}
    }

    const existingOutput = MOCK_OUTPUTS.find(o => o.id === output.id)
    if (existingOutput === undefined) {
      throw {error: 404, message: `No output with id '${output.id}'`}
    }

    existingOutput.name = output.name
    existingOutput.selectedInputId = output.selectedInputId
    return new Promise((resolve) => setTimeout(() => resolve(Object.assign({}, existingOutput)), MOCK_DELAY_MS))
  }

  fetchOutputsForAffiliate(affiliateId: string): Promise<Output[]> {
    const affiliate = MOCK_AFFILIATES.find(a => a.id === affiliateId)
    if (affiliate === undefined) {
      throw {error: 404, message: `No affiliate with id '${affiliateId}'`}
    }
    const outputIds = affiliate.outputIds === undefined ? [] : affiliate.outputIds
    const outputs: Output[] = MOCK_OUTPUTS.filter(o => outputIds.indexOf(o.id) > -1)
    return new Promise((resolve) => setTimeout(() => resolve(outputs), MOCK_DELAY_MS))
  }

  deleteOutput(affiliateId: string, outputId: string): Promise<void> {
    const affiliate = MOCK_AFFILIATES.find(a => a.id === affiliateId)
    if (affiliate === undefined) {
      throw {error: 404, message: `No affiliate with id '${affiliateId}'`}
    }

    // Remove from outputs
    const outputIndex = MOCK_OUTPUTS.findIndex(o => o.id === outputId);
    if (outputIndex> -1) {
      MOCK_OUTPUTS.splice(outputIndex, 1);
    }

    // Remove from affiliate outputIds
    if (affiliate.outputIds) {
      const index = affiliate.outputIds.findIndex(id => id === outputId);
      if (index > -1) {
        affiliate.outputIds.splice(index, 1);
      }
    }

    return new Promise((resolve) => setTimeout(() => resolve() , MOCK_DELAY_MS))
  }

  selectInput(affiliateId: string, inputFeed: InputFeed | null, outputId: string): Promise<void> {
    return this.sendNotificationMessage(affiliateId, outputId, inputFeed !== null ? inputFeed.channelId : null)

  }

  //FIXME: Remove after demo
  private async sendNotificationMessage(affiliateId: string, outputId: string, channelId: string | null) {
    const syeSystem =  "http://us2.poc.neti.systems:9000"
    console.log("Posting to " + syeSystem);
    const notification = new NotificationMessage(
      syeSystem,
      syeSystem,
      "CH_NOTIFICATION_ONLY",
      7777,
      "70c19aa98a119fd7c522fdd550c3776c",
      true,
      true,
      false,
      true,
      0,
      JSON.stringify({affiliateId: affiliateId, outputId : outputId, channelId: channelId})
    );

    return SyeApi.sendNotificationMessage(notification)
  }
}