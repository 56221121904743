import { InputFeedReducerState } from '../reducers/InputFeedReducer'
import InputFeed from '../../model/InputFeed'
import Affiliate from '../../model/Affiliate'

export class InputFeedSelectors {

  public static inputFeedsBelongingToAffiliate(affiliate: Affiliate | undefined, state: InputFeedReducerState): InputFeed[] {
    if (affiliate) {
      return state.filter((i: InputFeed) => affiliate.inputFeedIds.findIndex(inputIdBelongingToAffiliate => inputIdBelongingToAffiliate ===  i.id) > -1)
    }
    return []
  }
}