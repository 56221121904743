
export interface ImageFetcherDelegate {
  onImageFetchedForKey(key: string, imageUrl: string | undefined): void
}

type StringDictionary = { [key: string] : string }

export class ImageFetcher {
  private fetchImageTimer?: number
  private imagesToFetchById: StringDictionary = {}
  private keysBeingFetched = new Set<String>()
  private delegate: ImageFetcherDelegate

  public readonly fetchedImagesById: StringDictionary = {}

  constructor(delegate: ImageFetcherDelegate) {
    this.delegate = delegate

    this.startFetchingImagesForKeys = this.startFetchingImagesForKeys.bind(this)
    this.stopFetching = this.stopFetching.bind(this)
    this.performFetch = this.performFetch.bind(this)
    this.fetchImageForKey = this.fetchImageForKey.bind(this)
    this.notifyDelegate = this.notifyDelegate.bind(this)
  }




  public startFetchingImagesForKeys(entries: { [key: string]: string}) {
    if (this.fetchImageTimer === undefined) {
      this.fetchImageTimer = setInterval(this.performFetch, 1000) as any
    }

    this.imagesToFetchById = Object.assign({}, entries)
  }

  public stopFetching() {
    clearInterval(this.fetchImageTimer)
    this.imagesToFetchById = {}
    this.fetchImageTimer = undefined
  }
















  private performFetch() {
    Object.keys(this.imagesToFetchById).forEach(id => {
      const url = this.imagesToFetchById[id]
      this.fetchImageForKey(id, url)
    })
  }

  private fetchImageForKey(key: string, url: string): void {
    if (this.keysBeingFetched.has(key)) {
      //console.log("Already fetching image for " + key)
      return
    }

    this.keysBeingFetched.add(key)

    fetch(url).then(
      async (response) => {
        this.keysBeingFetched.delete(key)
        const data = await response.blob()
        const dataUrl = URL.createObjectURL(data)
        this.fetchedImagesById[key] = dataUrl
        this.notifyDelegate(key, dataUrl)
      },
      (error) => {
        console.log("Failed fetching image for key: " + key + ": error: " + error)
        this.keysBeingFetched.delete(key)
        delete this.fetchedImagesById[key]
        this.notifyDelegate(key, undefined)
      }
    )
  }

  private notifyDelegate(key: string, url: string | undefined) {
    if (this.imagesToFetchById[key]) {
      this.delegate.onImageFetchedForKey(key, url)
    }
  }
}