import React from "react";


const defaultStyle = { maxWidth: "100%", maxHeight: "100%" };

interface Props {
  src?: string
  alt?: string

  delay?: number
  duration?: number
  timingFunction?: string
  style?: any
}
interface State {
  topSrc?: string
  bottomOpacity: number,
  bottomSrc?: string
}

///Source: https://github.com/khanglu/react-crossfade-image
export default class CrossfadeImage extends React.PureComponent<Props, State> {
  private timeout?: number

  constructor(props: Props) {
    super(props);
    this.state = {
      topSrc: props.src,
      bottomOpacity: 0,
      bottomSrc: props.src
    };
  }

  componentWillReceiveProps(newProps: Props) {
    const oldSrc = this.state.topSrc;
    const newSrc = newProps.src;
    if (newSrc !== oldSrc) {
      // Reset the component everytime we receive new prop, to cancel out any animation that's still going on
      this.setState({ bottomSrc: undefined, topSrc: undefined}, () =>
        this.setState(
          // Opacity less than 1 takes precendence in stacking order
          { bottomSrc: oldSrc, topSrc: newSrc, bottomOpacity: 0.99 },
          () => {
            // Use setTimeout for getting 'fade out' transition without css keyframe
            if (this.timeout) {
              clearTimeout(this.timeout)
            }
            this.timeout = setTimeout(() => this.setState({ bottomOpacity: 0 }), 20) as any
          }
        )
      );
    }
  }

  render() {
    const { topSrc, bottomOpacity, bottomSrc } = this.state;

    const delay = this.props.delay ? this.props.delay : 0
    const duration = this.props.duration ? this.props.duration : 500
    const timingFunction = this.props.timingFunction ? this.props.timingFunction : 'ease'

    return (
      <div style={{
        ...defaultStyle,
        ...this.props.style,
        ...{ position: "relative" }
      }}>

        {
          topSrc &&
          <img src={topSrc}
               draggable={false}
               alt={this.props.alt}
               style={{
                 ...defaultStyle,
                 ...this.props.style,
                 ...{ position: "absolute" }
               }}
          />
        }
        {
          bottomSrc &&
          <img src={bottomSrc}
               draggable={false}
               style={{
                 ...defaultStyle,
                 ...this.props.style,
                 ...{
                   opacity: bottomOpacity,
                   transition: `opacity ${duration / 1000}s ${timingFunction} ${delay / 1000}s`
                 }
               }}
          />
        }
      </div>
    );
  }
}
