import React, { CSSProperties } from 'react'
import { createStyles, Theme, WithStyles } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography/Typography'
import { defaultParentInset, headerRowHeight } from '../../../styles'

const styles = (theme: Theme) => createStyles({
  headerRow: {
    /*
    // Uncomment to make sticky headers.
    // Note that the backgroundColor should not contain opacity when using sticky headers since the
    // background content becomes visible behind the header when scrolling.
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
    */

    width: '100%',
    height: `${headerRowHeight}px`,
    margin: '0px 0px 8px 0px',
    backgroundColor: theme.palette.background.default,

    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  headerTitle: {
    fontSize: "16px",
    textTransform: "uppercase",
    padding: `0px 4px`
  },
  
});

interface Props extends WithStyles<typeof styles> {
  title: string

  style?: CSSProperties
}
interface State {}

class HeaderRow extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div style={this.props.style}
           className={this.props.classes.headerRow}>
        <Typography noWrap={true} className={this.props.classes.headerTitle}>{this.props.title}</Typography>

        {this.props.children}

      </div>
    )
  }
}

export default withStyles(styles)(HeaderRow)