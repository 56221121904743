import React from 'react';
import LoginPage  from '../login/LoginPage'
import { BrowserRouter, HashRouter, Route } from 'react-router-dom'
import AppContainer from './AppContainer'
import User from '../../model/User'


interface Props {}

interface State {
  isAuthenticated: boolean
}

export class App extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { isAuthenticated: false }

    this.onAuthenticationSuccessful = this.onAuthenticationSuccessful.bind(this)
  }

  private onAuthenticationSuccessful(user: User) {
    this.setState({isAuthenticated: true})
  }

  render() {
    return this.state.isAuthenticated ?
      <HashRouter>
        <Route path='/' component={AppContainer}/>
      </HashRouter>
      :
      <LoginPage onAuthenticationSuccessful={this.onAuthenticationSuccessful}/>
  }
}