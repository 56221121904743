import * as React from 'react';
import { SyeVideoTrack } from '../../lib/sye/types'

interface Props {
  videoRotationAngle: number
  currentVideoTrack: SyeVideoTrack
  isVisible: boolean
}

interface State {
  playerContainerSize: {width: number, height: number}
}

export class VideoView extends React.PureComponent<Props, State> {
  public readonly mVideoSurfaceRef: React.RefObject<HTMLVideoElement> = React.createRef()
  private readonly mVideoSurfaceContainerRef: React.RefObject<HTMLDivElement> = React.createRef()


  constructor(props: Props) {
    super(props)
    this.state = {
      playerContainerSize: {width: 100, height: 100},
    }

    this.onDimensionsUpdated = this.onDimensionsUpdated.bind(this)
  }

  public onDimensionsUpdated() {
    if (!this.mVideoSurfaceContainerRef.current) { return }

    const playerContainerSize = this.mVideoSurfaceContainerRef.current.getBoundingClientRect()
    const updatedPlayerContainerSize = { width: playerContainerSize.width, height: playerContainerSize.height}
    //console.log("Updated dimensions: Size: " + JSON.stringify(playerContainerSize))
    this.setState({playerContainerSize: updatedPlayerContainerSize})
  }


  componentDidMount() {
    /// Listen to resize events and update video element accordingly
    window.addEventListener("resize", this.onDimensionsUpdated);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onDimensionsUpdated)
  }

  render() {
    /// Calculate video surface size to always fill entire width of screen
    /// Top and bot of video may be cropped
    let videoAspectRatio;
    let videoSurfaceHeight;
    let videoSurfaceWidth;

    let transform = '';

    const isVideoRotated = this.props.videoRotationAngle !== 0;
    if (isVideoRotated) {
      videoAspectRatio = this.props.currentVideoTrack.width / this.props.currentVideoTrack.height;
      /// Reverse width and height and make height with the width * aspect ratio
      videoSurfaceHeight = this.state.playerContainerSize.width
      videoSurfaceWidth = this.state.playerContainerSize.width * videoAspectRatio;

      const WIDTH_THRESHOLD = 1 //iPad Pro is 1024 pixels wide
      if (this.state.playerContainerSize.width > WIDTH_THRESHOLD) {
        //videoSurfaceHeight /= 2.0
        //videoSurfaceWidth /= 2.0
      }

      if (this.props.videoRotationAngle < 0) {
        transform = `translateY(${videoSurfaceWidth}px) rotate(${this.props.videoRotationAngle}deg)`
      } else {
        transform = `translateX(${videoSurfaceHeight}px) rotate(${this.props.videoRotationAngle}deg)`
      }

    } else {
      videoAspectRatio = this.props.currentVideoTrack.height / this.props.currentVideoTrack.width;
      videoSurfaceWidth = this.state.playerContainerSize.width
      videoSurfaceHeight = this.state.playerContainerSize.height * videoAspectRatio;
    }

    return (
      <div id="playerContainer"
           ref={this.mVideoSurfaceContainerRef}
           style={{
             width: '100%',
             height: '100%',
             backgroundColor: 'black',
             display: this.props.isVisible ? 'flex' : 'none',
             alignItems: 'center'
           }}>
        <video id='player'
               ref={this.mVideoSurfaceRef}
               style={{
                 display: 'block',
                 width: videoSurfaceWidth,
                 height: videoSurfaceHeight,
                 objectFit: 'contain',
                 transformOrigin: 'top left',
                 transform: transform
               }}/>
      </div>
    )
  }
}