import { AuthorizationLevel, default as User } from '../model/User'
import Affiliate from '../model/Affiliate'
import Output from '../model/Output'
import InputFeed from '../model/InputFeed'

export const MOCK_DELAY_MS = 500;

export const MOCK_USERS: User[] = [
  {username: "edgedemo", authorizationLevel: AuthorizationLevel.CREATE_READ_WRITE_DELETE},
  {username: "edgedemo1", authorizationLevel: AuthorizationLevel.CREATE_READ_WRITE_DELETE},
  {username: "edgedemo2", authorizationLevel: AuthorizationLevel.CREATE_READ_WRITE_DELETE},
  {username: "george", authorizationLevel: AuthorizationLevel.CREATE_READ_WRITE_DELETE},
  {username: "per", authorizationLevel: AuthorizationLevel.CREATE_READ_WRITE_DELETE},
  {username: "ulrik", authorizationLevel: AuthorizationLevel.CREATE_READ_WRITE_DELETE},
  {username: "love", authorizationLevel: AuthorizationLevel.CREATE_READ_WRITE_DELETE},
  {username: "jonas", authorizationLevel: AuthorizationLevel.CREATE_READ_WRITE_DELETE},
  {username: "tor", authorizationLevel: AuthorizationLevel.CREATE_READ_WRITE_DELETE},
  {username: "mats", authorizationLevel: AuthorizationLevel.CREATE_READ_WRITE_DELETE},
  {username: "tatademo", authorizationLevel: AuthorizationLevel.CREATE_READ_WRITE_DELETE},
  {username: "nbcdemo", authorizationLevel: AuthorizationLevel.CREATE_READ_WRITE_DELETE},
  {username: "kristian", authorizationLevel: AuthorizationLevel.CREATE_READ_WRITE_DELETE},
  {username: "olle", authorizationLevel: AuthorizationLevel.CREATE_READ_WRITE_DELETE},
  {username: "danpope", authorizationLevel: AuthorizationLevel.CREATE_READ_WRITE_DELETE}
]


const getThumbnailImageUrl = (channelId: string): string => {
  return `http://us2.poc.neti.systems:8000/${channelId}.jpg`
}

export let MOCK_INPUT_FEEDS: InputFeed[] = [
  {
    id: 'input-feed-1',
    channelId: 'CH_01',
    thumbnailUrl: getThumbnailImageUrl('svt1'),
    syeBaseUrl: 'http://us2.poc.neti.systems:8000',
    username: 'player',
    password: 'neti'
  },
  {
    id: 'input-feed-2',
    channelId: 'CH_02',
    thumbnailUrl: getThumbnailImageUrl('svt2'),
    syeBaseUrl: 'http://us2.poc.neti.systems:8000',
    username: 'player',
    password: 'neti'
  },
  {
    id: 'input-feed-3',
    channelId: 'CH_04',
    thumbnailUrl: getThumbnailImageUrl('kunskap'),
    syeBaseUrl: 'http://us2.poc.neti.systems:8000',
    username: 'player',
    password: 'neti'
  },
  {
    id: 'input-feed-4',
    channelId: 'CH_07',
    thumbnailUrl: getThumbnailImageUrl('barn'),
    syeBaseUrl: 'http://us2.poc.neti.systems:8000',
    username: 'player',
    password: 'neti'
  },
  {
    id: 'input-feed-5',
    channelId: 'CH_08',
    thumbnailUrl: getThumbnailImageUrl('tv4'),
    syeBaseUrl: 'http://us2.poc.neti.systems:8000',
    username: 'player',
    password: 'neti'
  }
]

export let MOCK_OUTPUTS: Output[] = [
  {
    id:"edgedemo1",
    name: "Demo Output 1",
    selectedInputId: "input-feed-1"
  },
  {
    id:"edgedemo2",
    name: "Demo Output 2",
    selectedInputId: null
  },
  {
    id:"edgedemo3",
    name: "Demo Output 3",
    selectedInputId: null
  },
  {
    id:"edgedemo4",
    name: "Demo Output 4",
    selectedInputId: null
  },
  {
    id:"edgedemo5",
    name: "Demo Output 5",
    selectedInputId: null
  },
  {
    id:"edgedemo6",
    name: "Demo Output 6",
    selectedInputId: null
  },
  {
    id:"edgedemo7",
    name: "Demo Output 7",
    selectedInputId: null
  },
  {
    id:"per1",
    name: "Per Output 1",
    selectedInputId: "input-feed-1"
  },
  {
    id:"per2",
    name: "Per Output 2",
    selectedInputId: null
  },
  {
    id:"george1",
    name: "George Output 1",
    selectedInputId: "input-feed-1"
  },
  {
    id:"george2",
    name: "George Output 2",
    selectedInputId: null
  },
  {
    id:"ulrik1",
    name: "Ulrik Output 1",
    selectedInputId: null
  },
  {
    id:"love1",
    name: "Love Output 1",
    selectedInputId: null
  },
  {
    id:"jonas1",
    name: "Jonas Output 1",
    selectedInputId: null
  },
  {
    id:"tor1",
    name: "Tor Output 1",
    selectedInputId: null
  },
  {
    id:"mats1",
    name: "Mats Output 1",
    selectedInputId: null
  },
  {
    id:"tatademo1",
    name: "Tata Output 1",
    selectedInputId: "input-feed-1"
  },
  {
    id:"tatademo2",
    name: "Tata Output 1",
    selectedInputId: "input-feed-2"
  },
  {
    id:"nbcdemo1",
    name: "NBC Output 1",
    selectedInputId: "input-feed-1"
  },
  {
    id:"nbcdemo2",
    name: "NBC Output 1",
    selectedInputId: "input-feed-2"
  },
  {
    id:"kristian1",
    name: "Kristian Output 1",
    selectedInputId: "input-feed-2"
  },
  {
    id:"kristian2",
    name: "Kristian Output 2",
    selectedInputId: "input-feed-2"
  },
  {
    id:"olle1",
    name: "Olle Output 1",
    selectedInputId: "input-feed-2"
  },
  {
    id:"olle2",
    name: "Olle Output 2",
    selectedInputId: "input-feed-2"
  },
  {
    id:"dan1",
    name: "Dan Output 1",
    selectedInputId: "input-feed-1"
  },
  {
    id:"dan2",
    name: "Dan Output 2",
    selectedInputId: null
  },
]

//HACK FOR MOCK PURPOSES:
//Prefix the id with the owning username
export let MOCK_AFFILIATES: Affiliate[] = [
  {
    id:"edgedemo-1",
    name: "Demo Affiliate 1",
    inputFeedIds: MOCK_INPUT_FEEDS.map(i => i.id),
    outputIds: MOCK_OUTPUTS.filter(o => o.id.startsWith("edgedemo")).map(o => o.id)
  },
  {
    id:"edgedemo-2",
    name: "Demo Affiliate 2",
    inputFeedIds: MOCK_INPUT_FEEDS.map(i => i.id),
    outputIds: MOCK_OUTPUTS.filter(o => o.id === "edgedemo2").map(o => o.id)
  },
  {
    id:"george-1",
    name: "George Affiliate",
    inputFeedIds: MOCK_INPUT_FEEDS.map(i => i.id),
    outputIds: MOCK_OUTPUTS.filter(o => o.id === "george1" || o.id === "george2").map(o => o.id)
  },
  {
    id:"per-1",
    name: "Per Affiliate",
    inputFeedIds: MOCK_INPUT_FEEDS.map(i => i.id),
    outputIds: MOCK_OUTPUTS.filter(o => o.id === "per1" || o.id === "per2").map(o => o.id)
  },
  {
    id:"ulrik-1",
    name: "Ulrik Affiliate",
    inputFeedIds: MOCK_INPUT_FEEDS.map(i => i.id),
    outputIds: MOCK_OUTPUTS.filter(o => o.id === "ulrik1").map(o => o.id)
  },
  {
    id:"love-1",
    name: "Love Affiliate",
    inputFeedIds: MOCK_INPUT_FEEDS.map(i => i.id),
    outputIds: MOCK_OUTPUTS.filter(o => o.id === "love1").map(o => o.id)
  },
  {
    id:"jonas-1",
    name: "Jonas Affiliate",
    inputFeedIds: MOCK_INPUT_FEEDS.map(i => i.id),
    outputIds: MOCK_OUTPUTS.filter(o => o.id === "jonas1").map(o => o.id)
  },
  {
    id:"tor-1",
    name: "Tor Affiliate",
    inputFeedIds: MOCK_INPUT_FEEDS.map(i => i.id),
    outputIds: MOCK_OUTPUTS.filter(o => o.id === "tor1").map(o => o.id)
  },
  {
    id:"mats-1",
    name: "Mats Affiliate",
    inputFeedIds: MOCK_INPUT_FEEDS.map(i => i.id),
    outputIds: MOCK_OUTPUTS.filter(o => o.id === "mats1").map(o => o.id)
  },
  {
    id:"tatademo-1",
    name: "Tata demo Affiliate",
    inputFeedIds: MOCK_INPUT_FEEDS.map(i => i.id),
    outputIds: MOCK_OUTPUTS.filter(o => o.id === "tatademo1" || o.id === "tatademo2").map(o => o.id)
  },
  {
    id:"nbcdemo-1",
    name: "NBC demo Affiliate",
    inputFeedIds: MOCK_INPUT_FEEDS.map(i => i.id),
    outputIds: MOCK_OUTPUTS.filter(o => o.id === "nbcdemo1" || o.id === "nbcdemo2").map(o => o.id)
  },
  {
    id:"kristian-1",
    name: "Kristians demo Affiliate",
    inputFeedIds: MOCK_INPUT_FEEDS.map(i => i.id),
    outputIds: MOCK_OUTPUTS.filter(o => o.id === "kristian1" || o.id === "kristian2").map(o => o.id)
  },
  {
    id:"olle-1",
    name: "Olle demo Affiliate",
    inputFeedIds: MOCK_INPUT_FEEDS.map(i => i.id),
    outputIds: MOCK_OUTPUTS.filter(o => o.id === "olle1" || o.id === "olle2").map(o => o.id)
  },
  {
    id:"danpope-1",
    name: "Dan demo Affiliate",
    inputFeedIds: MOCK_INPUT_FEEDS.map(i => i.id),
    outputIds: MOCK_OUTPUTS.filter(o => o.id === "dan1" || o.id === "dan2").map(o => o.id)
  },
]

