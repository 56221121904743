import React from 'react'
import LoginFormView from './LoginFormView'
import Dialog from '@material-ui/core/Dialog/Dialog'
import { GlobalState } from '../../redux/reducers/RootReducer'
import * as userActions from '../../redux/actions/UserActions';
import * as affiliateActions from '../../redux/actions/AffiliateActions';
import * as inputFeedActions from '../../redux/actions/InputFeedActions';
import { connect } from 'react-redux'
import { ThunkDispatch } from '../../redux/reducers/RootReducer'
import User from '../../model/User'
import { MockOutputApi } from '../../api/output/MockOutputApi'

type GlobalStateProps = ReturnType<typeof mapStateToProps>
type IDispatchProps = ReturnType<typeof mapDispatchToProps>

interface Props extends GlobalStateProps, IDispatchProps {};
interface State {
  errorMessage: string
  isAuthenticating: boolean
}

class LoginPage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      errorMessage: '',
      isAuthenticating: false,
    }

    this.onSubmit = this.onSubmit.bind(this)
  }

  private onSubmit(username: string, password: string) {
    this.setState({ errorMessage: '', isAuthenticating: true }, async () => {
      try {
        const user = await this.props.fetchUser(username, password)
        //TODO: Move this elsewhere
        await this.props.fetchInputFeeds()
        await this.props.fetchAffiliatesForUser(user.username)

        //TODO: remove this ugly hack that sets outputs to ch_01 on login
        this.props.affiliates[0].outputIds.map( output => {
          new MockOutputApi().selectInput(this.props.selectedAffiliateId as string, this.props.inputFeeds[0], output).then(
            () => {
              // console.log('Sent successfully!')
              this.props.onAuthenticationSuccessful(user)
            },
            (error: any) => {
              console.log('Failed: ' + error.response + ' ' + error.status + ' ' + error)
              this.props.onAuthenticationSuccessful(user)
            })
        })
      } catch (error) {
        this.setState({
          errorMessage: 'Login failed',
          isAuthenticating: false
        })
      }
    })
  }

  render() {
    return (
      <Dialog open={true} fullScreen={true}>
      <LoginFormView errorMessage={this.state.errorMessage}
                          isLoading={this.state.isAuthenticating}
                          onLoginSubmitted={this.onSubmit}/>
      </Dialog>
    )
  }
}



/*
TODO: Fix proper typing with mapStateToPros and mapDispatchToProps. I want to be using bindActionCreators
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return bindActionCreators({
      fetchUser: userActions.fetchUser
    }, dispatch
  )
}
*/

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    fetchUser: (username: string, password: string) => dispatch(userActions.fetchUser(username, password)),
    fetchInputFeeds: () => dispatch(inputFeedActions.fetchInputFeeds()),
    fetchAffiliatesForUser: (username: string) => dispatch(affiliateActions.fetchAffiliatesForUser(username)),
  }
}

const mapStateToProps = (state: GlobalState, ownProps: {
  onAuthenticationSuccessful: (user: User) => void;
}) => {
  return {
    affiliates: state.affiliates,
    inputFeeds: state.inputFeeds,
    selectedAffiliateId: state.selectedAffiliateId,
    outputsByAffiliateId: state.outputsByAffiliateId,
    ...ownProps
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);