import User from '../../model/User'

export interface IUserApi {
  login(username: string, password: string): Promise<User>
}

export class UserApi implements IUserApi {
  login(username: string, password: string): Promise<User> {
    throw "Not implemented"
  }
}