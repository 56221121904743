import React from 'react'
import {connect} from "react-redux";
import { GlobalState } from '../../../redux/reducers/RootReducer'
import User, { AuthorizationLevel, getAuthorizationLevelFromValue } from '../../../model/User'


/**
* The props that will be injected to the Wrapped component
*/
export interface AuthorizedProps {
  /**
   * The authorization level of the user, represented as a AuthoriationLevel.
   * It is up to the wrapped component to make render itself accordingly
   * E.g:
   * render() {
   *   switch (this.props.authorizationLevel) {
   *     case AuthorizationLevel.UNAUTHORIZED: return "Not authorized"
   *     case AuthorizationLevel.READ: return "Read only"
   *     case AuthorizationLevel.READ_WRITE: return "Read/Write"
   *     case AuthorizationLevel.CREATE_READ_WRITE_DELETE: return "Create/Read/Write/Delete"
   *   }
   */
  authorizationLevel: AuthorizationLevel
}

interface IGlobalStateProps {
  currentUser: User
}
interface State {}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
type Subtract<T, K> = Omit<T, keyof K>;


/**
 * 'withAuthorization' is a HOC (Higher-Ordered Component), i.e. it is a function
 * that takes a component and returns another component, that wraps the inner component.
 *
 * It subscribes to the 'user' in the redux state and injects prop 'authorizationLevel: number' into the wrapped component
 */
export default function withAuthorization<WrappedProps extends AuthorizedProps>(WrappedComponent: React.ComponentType<WrappedProps>): React.ComponentType<Subtract<WrappedProps, AuthorizedProps>> {

  class WithAuthorization extends React.Component<IGlobalStateProps, State> {
    constructor(props: IGlobalStateProps) {
      super(props);
      this.state = {};
    }
    render() {
      const userAuthLevel = getAuthorizationLevelFromValue(this.props.currentUser.authorizationLevel)
      return <WrappedComponent {...this.props} authorizationLevel={userAuthLevel}/>;
    }
  }

  (WithAuthorization as React.ComponentType<IGlobalStateProps>).displayName = `WithAuthorization(${getDisplayName(WrappedComponent)})`;

  return connect(mapStateToProps)(WithAuthorization) as any
}

function getDisplayName(WrappedComponent: React.ComponentType<any>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * mapStateToProps
 * @param {GlobalState} state - all data contained within the redux store
 * @returns {{user: any}}  - a slice of the state, i.e. properties from state which will be accessible in our component's props object.
 */
function mapStateToProps(state: GlobalState) {
  return { currentUser: state.user };
}
