export enum AuthorizationLevel {
  /** Not authorized */
  UNAUTHORIZED = 0,

  /** Read only */
  READ = 1000,

  /** Read and update */
  READ_WRITE = 2000,

  /** Create, read, update, delete */
  CREATE_READ_WRITE_DELETE = 3000,
}

export default interface User {
  username: string
  authorizationLevel: number
}

export function getAuthorizationLevelFromValue(authValue: number): AuthorizationLevel {
  if (authValue >= AuthorizationLevel.CREATE_READ_WRITE_DELETE) {
    return AuthorizationLevel.CREATE_READ_WRITE_DELETE
  }
  if (authValue >= AuthorizationLevel.READ_WRITE) {
    return AuthorizationLevel.READ_WRITE
  }
  if (authValue >= AuthorizationLevel.READ) {
    return AuthorizationLevel.READ
  }
  return AuthorizationLevel.UNAUTHORIZED
}