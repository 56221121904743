import Affiliate from '../../model/Affiliate'

export interface IAffiliateApi {
  createAffiliate(affiliate: Affiliate): Promise<Affiliate>
  updateAffiliate(affiliate: Affiliate): Promise<Affiliate>
  fetchAffiliatesForUser(username: string): Promise<Affiliate[]>
  deleteAffiliate(affiliateId: string): Promise<void>
}

export class AffiliateApi implements IAffiliateApi {

  createAffiliate(affiliate: Affiliate): Promise<Affiliate> {
    throw "Not implemented"
  }

  updateAffiliate(affiliate: Affiliate): Promise<Affiliate> {
    throw "Not implemented"
  }

  fetchAffiliatesForUser(username: string): Promise<Affiliate[]> {
    throw "Not implemented"
  }

  deleteAffiliate(affiliateId: string): Promise<void> {
    throw "Not implemented"
  }
}