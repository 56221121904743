import {Action} from "redux";
import { FetchUserSuccessAction, } from "./UserActions";
import {
  CreateAffiliateSuccessAction,
  DeleteAffiliateSuccessAction,
  FetchAffiliatesSuccessAction, SelectAffiliateAction, UpdateAffiliateSuccessAction
} from './AffiliateActions'
import {
  CreateOutputSuccessAction,
  DeleteAffiliateOutputsAction,
  DeleteOutputSuccessAction,
  FetchOutputsSuccessAction, UpdateOutputSuccessAction
} from './OutputActions'
import { FetchInputFeedsSuccessAction } from './InputFeedActions'

/***** Action types *****/

export enum ActionTypeKeys {
  FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS',

  FETCH_INPUT_FEEDS_SUCCESS = 'FETCH_INPUT_FEEDS_SUCCESS',

  SELECT_AFFILIATE = 'SELECT_AFFILIATE',
  CREATE_AFFILIATE_SUCCESS = 'CREATE_AFFILIATE_SUCCESS',
  UPDATE_AFFILIATE_SUCCESS = 'UPDATE_AFFILIATE_SUCCESS',
  FETCH_AFFILIATES_SUCCESS = 'FETCH_AFFILIATES_SUCCESS',
  DELETE_AFFILIATE_SUCCESS = 'DELETE_AFFILIATE_SUCCESS',

  CREATE_OUTPUT_SUCCESS = 'CREATE_OUTPUT_SUCCESS',
  UPDATE_OUTPUT_SUCCESS = 'UPDATE_OUTPUT_SUCCESS',
  FETCH_OUTPUTS_SUCCESS = 'FETCH_OUTPUTS_SUCCESS',
  DELETE_OUTPUT_SUCCESS = 'DELETE_OUTPUT_SUCCESS',
  DELETE_AFFILIATE_OUTPUTS = 'DELETE_AFFILIATE_OUTPUTS',

  OTHER_ACTION = "__any_other_action_type__"
}

/***** Actions *****/

export interface OtherAction extends Action {
  type: ActionTypeKeys.OTHER_ACTION;
}

export type ActionType =
  | FetchUserSuccessAction

  | FetchInputFeedsSuccessAction

  | SelectAffiliateAction
  | CreateAffiliateSuccessAction
  | UpdateAffiliateSuccessAction
  | FetchAffiliatesSuccessAction
  | DeleteAffiliateSuccessAction

  | CreateOutputSuccessAction
  | UpdateOutputSuccessAction
  | FetchOutputsSuccessAction
  | DeleteOutputSuccessAction
  | DeleteAffiliateOutputsAction

  | OtherAction;


