import React from 'react'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import { container } from '../../../styles'
import AppBar from '@material-ui/core/AppBar/AppBar'
import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import Button from '@material-ui/core/Button/Button'
import Hidden from '@material-ui/core/Hidden/Hidden'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Menu from '@material-ui/icons/Menu'
import AppNavigationBarLinks from './AppNavigationBarLinks'
import Affiliate from '../../../model/Affiliate'
import { defaultParentInset, headerRowHeight } from '../../../styles'


const styles = (theme: Theme) => createStyles({
  appBar: {
    position: "absolute",
    display: "block",
    width: "100%",
    height: `${headerRowHeight}px`,
    padding: 0,

    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderBottom: "0",
    zIndex: theme.zIndex.appBar,
    transition: "all 150ms ease 0s",

  },
  container: {
    ...container,
    //backgroundColor: 'pink',
  },
  flex: {
    flex: 1,
    //backgroundColor: 'blue',
  },
  title: {
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "uppercase",
    color: "inherit",
    margin: "0",
    "&:hover,&:focus": {
      background: "transparent"
    }
  },
});

interface Props extends WithStyles<typeof styles>{
  ///The currently logged in user
  username: string
  affiliates: Affiliate[]
  selectedAffiliateIndex: number
  onAffiliateSelected: (index: number) => void

  title: string
  handleDrawerToggle: () => void;
}
interface State {}

class AppNavigationBar extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
        <AppBar className={this.props.classes.appBar}>
          <Toolbar className={this.props.classes.container}>
            <div className={this.props.classes.flex}>

              {/* Navbar title based on route name */}
              <Button href="#" className={this.props.classes.title}>
                { this.props.title }
              </Button>
            </div>

            <Hidden smDown>
              <AppNavigationBarLinks affiliates={this.props.affiliates}
                           selectedAffiliateIndex={this.props.selectedAffiliateIndex}
                           onAffiliateSelected={this.props.onAffiliateSelected}
              />
            </Hidden>

            {/* Toggle sidebar button - hidden on >= medium */}
            <Hidden mdUp>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.props.handleDrawerToggle}>
                <Menu />
              </IconButton>
            </Hidden>

          </Toolbar>
        </AppBar>
    );
  }
}

export default withStyles(styles)(AppNavigationBar)