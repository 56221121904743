import InputFeed from '../../model/InputFeed'
import { IInputApi } from './InputApi'
import { MOCK_DELAY_MS, MOCK_INPUT_FEEDS} from '../MockData'


export class MockInputApi implements IInputApi {

  fetchInputFeeds(): Promise<InputFeed[]> {
    return new Promise((resolve) => setTimeout(() => resolve(Object.assign([], MOCK_INPUT_FEEDS)), MOCK_DELAY_MS))
  }
}