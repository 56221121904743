import React from 'react'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import { getRouteMatchingPath, getTopLevelRoutes } from '../../routes/index'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import Sidebar from './navigation/Sidebar'

import { container, drawerWidth, transition } from '../../styles'
import AppNavigationBar from './navigation/AppNavigationBar'
import { GlobalState, ThunkDispatch } from '../../redux/reducers/RootReducer'
import { connect } from 'react-redux'
import * as affiliateActions from '../../redux/actions/AffiliateActions';


const styles = (theme: Theme) => createStyles({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100vh",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    padding: "15px 15px 0 15px"
  },
  container,
  map: {
    marginTop: "70px"
  }
});

type GlobalStateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>

interface Props extends GlobalStateProps, DispatchProps, RouteComponentProps<any>, WithStyles<typeof styles> {}
interface State {
  isMobileSidebarOpen: boolean
}

/**
 * This class is responsible for:
 * - deciding with items in the side navigation bar the user is authorized to view
 * - toggling the side navigation bar
 *
 * - and acts as a container class for
 *   - the side navigation bar
 *   - the app header navigation bar
 *   - and the current content view (based on the current route/url path.
 *
 * The app header navigation bar and the <Switch> (that renders a view based on the current path) are rendered inside a div with a mainPanel ref
 *
 */
class AppContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {isMobileSidebarOpen: false}

    this.toggleSidebar = this.toggleSidebar.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.onAffiliateSelected = this.onAffiliateSelected.bind(this)
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  private handleResize() {
    /// Hide side bar on resize
    const MEDIUM_BREAKPOINT = 960
    if (window.innerWidth >= MEDIUM_BREAKPOINT) {
      this.setState({ isMobileSidebarOpen: false });
    }
  }

  private toggleSidebar() {
    this.setState((prevState) => ({isMobileSidebarOpen: !prevState.isMobileSidebarOpen}))
  }

  private onAffiliateSelected(index: number) {
    const affiliate = this.props.affiliates[index]
    this.props.selectAffiliate(affiliate.id)
  }

  render() {
    const currentRoute = getRouteMatchingPath(this.props.location.pathname);
    const currentRouteName = currentRoute ? currentRoute.navbarName : "UNKNOWN";
    const topLevelRoutes = getTopLevelRoutes(this.props.user.authorizationLevel)
    const selectedAffiliateIndex = this.props.affiliates.findIndex(a => a.id === this.props.selectedAffiliateId)

    return (
      <div className={this.props.classes.wrapper}>
        <Sidebar username={this.props.user.username}
                 affiliates={this.props.affiliates}
                 selectedAffiliateIndex={selectedAffiliateIndex}
                 onAffiliateSelected={this.onAffiliateSelected}
                 open={this.state.isMobileSidebarOpen}
                 onSideBarClosed={this.toggleSidebar}
                 navLinks={topLevelRoutes}
                 activePath={this.props.location.pathname}
        />

        <div className={this.props.classes.mainPanel} ref="mainPanel">
          {false && <AppNavigationBar username={this.props.user.username}
                  affiliates={this.props.affiliates}
                  selectedAffiliateIndex={selectedAffiliateIndex}
                  onAffiliateSelected={this.onAffiliateSelected}
                  title={ currentRouteName }
                  handleDrawerToggle={this.toggleSidebar}/>}

          <div className={this.props.classes.content}>
            <div className={this.props.classes.container}>
              <Switch>
                { topLevelRoutes.map((prop, key) => {
                  return <Route path={prop.path} component={prop.component} key={key}/>;
                })}

                { /* Redirect the user to the first route if no routes in the above switch matched the current path */}
                <Redirect to={topLevelRoutes[0].path}/>
              </Switch>
              </div>
          </div>
        </div>

      </div>
    );
  }
}

function mapStateToProps(state: GlobalState) {
  return {
    user: state.user,
    affiliates: state.affiliates,
    selectedAffiliateId: state.selectedAffiliateId
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    selectAffiliate: (affiliateId: string) => dispatch(affiliateActions.selectAffiliate(affiliateId)),
  }
}
const connected = connect(mapStateToProps, mapDispatchToProps)(AppContainer)
const styledAndConnected = withStyles(styles)(connected)
export default styledAndConnected