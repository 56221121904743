import {ActionTypeKeys} from './ActionTypes';

import { Action, Dispatch } from 'redux'
import { GlobalState, ThunkResult } from '../reducers/RootReducer'
import { IApi } from '../../api/Api'
import Affiliate from '../../model/Affiliate'
import * as outputActions from '../../redux/actions/OutputActions'
import { OutputReducerState } from '../reducers/OutputReducer'

/* ACTIONS */

export interface SelectAffiliateAction extends Action {
  type: ActionTypeKeys.SELECT_AFFILIATE,
  affiliateId: string | null;
}

export interface CreateAffiliateSuccessAction extends Action {
  type: ActionTypeKeys.CREATE_AFFILIATE_SUCCESS
  affiliate: Affiliate;
}

export interface UpdateAffiliateSuccessAction extends Action {
  type: ActionTypeKeys.UPDATE_AFFILIATE_SUCCESS
  affiliate: Affiliate;
}

export interface FetchAffiliatesSuccessAction extends Action {
  type: ActionTypeKeys.FETCH_AFFILIATES_SUCCESS
  affiliates: Affiliate[];
}

export interface DeleteAffiliateSuccessAction extends Action {
  type: ActionTypeKeys.DELETE_AFFILIATE_SUCCESS
  affiliateId: string;
}


/* THUNK ACTION CREATORS */

export function selectAffiliate(selectedAffiliateId: string): ThunkResult<Promise<Affiliate | null>> {
  return (dispatch: Dispatch<any>, getState: () => GlobalState, api: IApi): Promise<Affiliate | null> => {
    let newlySelectedAffiliate = getState().affiliates.find(a => a.id === selectedAffiliateId)
    if (newlySelectedAffiliate) {
      updateSelectedAffiliateId(
        newlySelectedAffiliate.id,
        getState().selectedAffiliateId,
        getState().affiliates,
        getState().outputsByAffiliateId,
        dispatch)
    }

    return Promise.resolve(newlySelectedAffiliate ? newlySelectedAffiliate: null)
  };
}

export function createAffiliate(affiliate: Affiliate): ThunkResult<Promise<Affiliate>> {
  return (dispatch: Dispatch, getState: () => GlobalState, api: IApi): Promise<Affiliate> => {
    return api.affiliateApi.createAffiliate(affiliate).then(
      createdAffiliate => {
        dispatch({type: ActionTypeKeys.CREATE_AFFILIATE_SUCCESS, affiliate: createdAffiliate })

        updateSelectedAffiliateId(
          getState().selectedAffiliateId,
          getState().selectedAffiliateId,
          [...getState().affiliates, createdAffiliate],
          getState().outputsByAffiliateId,
          dispatch)

        return createdAffiliate
      },
      error => {
        throw error
      })
  };
}

export function updateAffiliate(affiliate: Affiliate): ThunkResult<Promise<Affiliate>> {
  return (dispatch: Dispatch<UpdateAffiliateSuccessAction>, getState: () => GlobalState, api: IApi): Promise<Affiliate> => {
    return api.affiliateApi.updateAffiliate(affiliate).then(
      updated => {
        dispatch({type: ActionTypeKeys.UPDATE_AFFILIATE_SUCCESS, affiliate: updated})
        return updated
      },
      error => {
        throw error
      })
  };
}

export function fetchAffiliatesForUser(username: string): ThunkResult<Promise<Affiliate[]>> {
  return (dispatch: Dispatch, getState: () => GlobalState, api: IApi): Promise<Affiliate[]> => {
    return api.affiliateApi.fetchAffiliatesForUser(username).then(
      affiliates => {
        dispatch({ type: ActionTypeKeys.FETCH_AFFILIATES_SUCCESS, affiliates: affiliates});

        updateSelectedAffiliateId(
          getState().selectedAffiliateId,
          getState().selectedAffiliateId,
          affiliates,
          getState().outputsByAffiliateId,
          dispatch)

        return affiliates
      },
      error => {
        throw error
      })
  };
}

export function deleteAffiliate(affiliateId: string): ThunkResult<Promise<void>> {
  return (dispatch: Dispatch, getState: () => GlobalState, api: IApi): Promise<void> => {
    return api.affiliateApi.deleteAffiliate(affiliateId).then(
      () => {
        dispatch({type: ActionTypeKeys.DELETE_AFFILIATE_SUCCESS, affiliateId: affiliateId})
        dispatch({type: ActionTypeKeys.DELETE_AFFILIATE_OUTPUTS, affiliateId: affiliateId})

        const remainingAffiliates = getState().affiliates.filter(a => a.id !== affiliateId)
        updateSelectedAffiliateId(
          getState().selectedAffiliateId,
          getState().selectedAffiliateId,
          remainingAffiliates,
          getState().outputsByAffiliateId,
          dispatch)
      },
      error => {
        throw error
      })
  };
}







function updateSelectedAffiliateId(desiredAffiliateId: string | null,
                                   currentAffiliateId: string | null,
                                   availableAffiliates: Affiliate[],
                                   availableOutputs: OutputReducerState,
                                   dispatch: Dispatch<any>): string | null {
  const affiliateIdToSelect = getValidAffiliate(desiredAffiliateId, availableAffiliates)

  if (affiliateIdToSelect !== currentAffiliateId) {
    // Update state with the newly selected affiliate
    dispatch({ type: ActionTypeKeys.SELECT_AFFILIATE, affiliateId: affiliateIdToSelect})

    if (affiliateIdToSelect !== null) {
      const needToFetchOutputs = availableOutputs[affiliateIdToSelect] === undefined
      if (needToFetchOutputs) {
        // Fetch outputs for the newly selected affiliate
        dispatch(outputActions.fetchOutputsForAffiliate(affiliateIdToSelect))
      }
    }
  }

  return affiliateIdToSelect
}

function getValidAffiliate(desiredAffiliateId: string | null,
                           availableAffiliates: Affiliate[]): string | null {

  // The desired affiliate is invalid if:
  // - no affiliate was previously selected or
  // - the currently selected affiliate is not available in the new list
  const isDesiredAffiliateInvalid =
    desiredAffiliateId === null ||
    availableAffiliates.find(a => a.id === desiredAffiliateId) === undefined

  if (isDesiredAffiliateInvalid) {
    // Select the first affiliate in the list of available affiliates or deselect the currently selected affiliate if no affiliates are available
    return availableAffiliates.length > 0 ? availableAffiliates[0].id : null
  }
  // The desired affiliate is valid
  return desiredAffiliateId
}
