import { createMuiTheme } from '@material-ui/core/styles'

export const applicationTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#119DA4',
      contrastText: "#21282B"
    },
    secondary: {
      main: '#119DA4',
      contrastText: "#21282B"
    },
    error: {
      main: '#FECD00',
    },
    action: {
      // active: "rgba(17, 157, 164, 0.6)",
      selected: "rgba(17, 157, 164, 0.6)",
      // hover:  "#119DA4",
      // hoverOpacity: 0.8,
      // disabled: "rgba(256,256,256, 0.6)",
      // disabledBackground: "#364046"
    },
    contrastThreshold: 3,
    background: {
      default: "#364046",
      paper: '#21282B'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: "'Hind', sans-serif"
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#364046",
      }
    },
    MuiListItem: {
      root: {
        '&$selected, &$selected:hover, &$selected:focus': {
          backgroundColor: "rgba(17, 157, 164, 0.6)",
        }
      },
      button: {
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: "rgba(17, 157, 164, 0.8)",
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
        '&:focus': {
          backgroundColor: "rgba(17, 157, 164, 0.6)",
        },
      },
      selected: {
        backgroundColor: "rgba(17, 157, 164, 0.6)",
      }
    },
  }
});

export const drawerWidth = 240;
export const headerRowHeight = 64;

//TODO: Apply this everywhere where we have an element inset from its parent
export const defaultParentInset = 20;

export const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

export const defaultFontProperties = {
  fontFamily: "'Hind', sans-serif",
  fontWeight: 400,
  lineHeight: "1.4em"
};

export const container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto"
};