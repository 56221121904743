/**
 * Usage example:
   ```
   const anyWindow = (window as any)
   const syeClientModule: ISyeAPI = anyWindow['SyeClient'];
   const apiConfig = new syeClientModule.SyeAPIConfig()
   syeClientModule.Setup(apiConfig).then(
     () => { console.log("Setting up Sye SDK succeeded") },
     () => {console.log("Setting up Sye SDK failed")}
   )
   ```
 */
export interface ISyeAPI {

  /**
   * @brief Setup function. When promise is resolved, further calls
   *        can be made towards the SDK, e.g. fetch channels or create players.
   *        Calls made towards the SDK will fail before the promise is resolved
   *        as the WebAssembly code is not loaded.
   * @param {SyeAPIConfig} aSyeApiConfig - A SYE API Configuration, currently not used.
   * @return Promise that is resolved when the web assembly is loaded and it is safe to proceed.
   */
  Setup(aSyeApiConfig: SyeAPIConfig): Promise<any>

  /**
   * @brief Function to Fetch the available channels from the Sye system.
   *        A SyeSystem with URLs and credentials must be specified along with two
   *        callback functions, one for success and one for error.
   * Throws TypeError exception if input arguments are incorrect.
   * @param {SyeSystem} aSyeSystem - A SyeSystem object containing a description of the Sye System.
   * @param {(aChannelArray: SyeChannel[])} onChannelSuccessCb - A callback function for successful retrieval of a channel list.
   * @param {(aCode: SyeFetchChannelsError, aText: string)} onChannelErrorCb - A callback function that is called when retrieval fails.
   */
  FetchChannels(aSyeSystem:         SyeSystem,
                onChannelSuccessCb: (aChannelArray: SyeChannel[]) => void,
                onChannelErrorCb:   (aCode: SyeFetchChannelsError, aText: string) => void): void

  /**
   * @brief Function create a SyePlayer instance, connecting it to a HTMLVideoElement. The HTMLVideoElement must be encapsulated by a HTMLDivElement
   *        for rendering of Closed Captions. If the parent element to the HTMLVideoElement is not a HTMLDivElement, a TypeError exception is thrown.
   *        Throws TypeError exception if input arguments are incorrect.
   *        A return value of undefined implies that the browser is not supported.
   * @param {SyeSystem} aSyeSystem - A SyeSystem object containing a description of the Sye System.
   * @param {HTMLVideoElement | null} aVideoElement - A HTML Video Element that the player shall use. Pass 'null' if SyePlayer.attachView() is used.
   * @param { (aCode: SyePlayerError, aDescription: string) } onErrorCb - Callback Function for permanent errors.
   * @param { (aCode: SyePlayerError, aDescription: string, aRetryAfterMillis: number) } onErrorRetryCb - Callback Function upon error. Reattempt can be perfomed after the indicated time in milliseconds.
   * @return {SyePlayer | undefined} - A Sye Player instance or undefined
   */
  CreatePlayer(aSyeSystem:        SyeSystem,
               aVideoElement:     HTMLVideoElement | null,
               onErrorCb:         (aCode: SyePlayerError, aDescription: string) => void,
               onErrorRetryCb:    (aCode: SyePlayerError, aDescription: string, aRetryAfterMillis: number) => void): ISyePlayer | undefined

  /**
   @brief The current sync time.

   * A SyePlayer user may receive data events, such as Notification messages.
   * These events have an associated timestamp (expressed in sync time) relative to the current sync time.
   * This method provides a means to sync data events, e.g. to be able to calculate when they should be processed/presented.
   * @see SyeNotificationMessage
   * @return The current sync time.
   */
  syncTimeMicros(): number

  /**
   * @brief Returns the SDK Version as a string on the form major.minor.build form.
   * @return {string} A SDK Version string
   */
  SdkVersion(): string
}


/**
 * @interface SyePlayer - Encapsulates a HTMLVideoElement/Media Source Extensions, a RTCPeerConnection and RTCPeerDataChannel and interfaces the
 *        Sye WebAssembly Library.
 */
export interface ISyePlayer {

  /**
   * @brief Callback method for high frequency stream correlation data.
   * The property must be set to a function with the following signature:
   * function(aTrackId: number, aStreamId: number, aLocalTime: number, aData: Uint8Array) => void
   */
  onCorrelationStreamCallback: ((aTrackId: number, aStreamId: number, aLocalTime: number, aData: Uint8Array) => void) | null;

  /**
   * @brief Callback method for notification messages.
   * The property must be set to a function with the following signature:
   * function(aSyeNotificationMessage: SyeNotificationMessage) => void
   */
  onNotificationMessageCallback: ((aSyeNotificationMessage: SyeNotificationMessage) => void) | null;

  /**
   * @brief Callback method for Available Audio Languages.
   * The property must be set to a function with the following signature:
   * function(aAvailableAudioLanguages: Array<string>) => void
   */
  onAvailableAudioLanguagesCallback: ((aAvailableAudioLanguages: Array<string>) => void) | null;

  /**
   * @brief Callback method for available closed captions channels.
   * The property must be set to a function with the following signature:
   * function(aChannelIndexes: Array<ChannelIndex>) => void
   */
  onAvailableClosedCaptionsChannelsCallback: ((aChannelIndexes: Array<ChannelIndex>) => void) | null;

  /**
   * @brief Callback method for all available audio tracks.
   * The property must be set to a function with the following signature:
   * function(aAudioTracksArray: SyeAudioTrack[]) => void
   */
  onAudioTracksCallback: ((aAudioTracksArray: SyeAudioTrack[]) => void) | null;

  /**
   * @brief Callback method for all available video tracks. Function is called with an array
   *        of JSON objects representing a video track.
   * The property must be set to a function with the following signature:
   * function(aVideoTracksArray: SyeVideoTrack[]) => void
   */
  onVideoTracksCallback: ((aVideoTracksArray: SyeVideoTrack[]) => void) | null;

  /**
   * @brief Callback method for audio track change.
   * The property must be set to a function with the following signature:
   * function(aFromTrack: SyeAudioTrack, aToTrack: SyeAudioTrack) => void
   */
  onAudioTrackChangeCallback: ((aFrom: SyeAudioTrack | undefined, aTo: SyeAudioTrack) => void) | null;

  /**
   * @brief Callback method for video track change.
   * The property must be set to a function with the following signature:
   * function(aFromTrackJsonObj: SyeVideoTrack | undefined, aToTrackJsonObj: SyeVideoTrack) => void
   */
  onVideoTrackChangeCallback: ((aFromTrackJsonObject: SyeVideoTrack | undefined, aToTrackJsonObject: SyeVideoTrack) => void) | null;

  /**
   * @brief Callback method that is called upon permanent error situations.
   *        User intervention is normally required.
   * The property must be set to a function with the following signature:
   * function(aCode: SyePlayerError, aDescription: string) => void
   */
  onErrorCallback: ((aCode: SyePlayerError, aDescription: string) => void) | null;

  /**
   * @brief Callback method that is called upon permanent error situations.
   *        User intervention is normally required.
   * The property must be set to a function with the following signature:
   * function(aCode: SyePlayerError, aDescription: string, aRetryAfterMillis: number) => void
   */
  onErrorRetryCallback: ((aCode: SyePlayerError, aDescription: string, aRetryAfterMillis: number) => void) | null;

  /**
   * @brief Callback method when the player state changes.
   * The property must be set to a function with the following signature:
   * function(aFromState: SyePlayerState, aToState: SyePlayerState) => void
   */
  onStateChangeCallback: ((aFromState: SyePlayerState, aToState: SyePlayerState) => void) | null;

  /**
   * @brief Callback for Timeline Update
   * The property must be set to a function with the following signature:
   * function(aSyeTimelineInfo: SyeTimelineInfo) => void
   * See @param {SyeTimelineInfo}
   */
  onTimelineUpdateCallback: ((aSyeTimelineInfo: SyeTimelineInfo) => void) | null;


  //FIXME: Should 'onSynchronizationOffsetCallback' be exposed or not? If so - add documentation
  //onSynchronizationOffsetCallback: ((aSynchronizationData: SyeSynchronizationData) => void) | null;

  /**
   * @brief Called after a Play(...) request, when an egress has been allocated and the player is ready to initiate contact with the egress.
   *
   * This callback is useful in order to determine if UDP is working.
   * E.g. On receiving this event the application can start a timer and then wait for a subsequent 'SyeEgressContactEvent'. If the 'SyeEgressContactEvent' happens before the timer fires then UDP is deemed working.
   * The property must be set to a function with the following signature:
   * function(aEvent: SyeEgressAllocationEvent) => void
   * @see @param {SyeEgressAllocationEvent}
   */
  onEgressAllocatedCallback: ((aEvent: SyeEgressAllocationEvent) => void) | null;

  /**
   * @brief Called when the player has successfully received a response (i.e. a UDP packet) from the egress.
   * The property must be set to a function with the following signature:
   * function(aEvent: SyeEgressContactEvent) => void
   * @see @param {SyeEgressContactEvent}
   */
  onEgressContactCallback: ((aEvent: SyeEgressContactEvent) => void) | null;

  /**
   * @brief Called when the player has received the first video frame from the egress.
   * The property must be set to a function with the following signature:
   * function(aEvent: SyeEgressFrameEvent) => void
   * @see @param {SyeEgressFrameEvent}
   */
  onTimeToFirstFrameCallback: ((aEvent: SyeEgressFrameEvent) => void) | null;


  /**
   * @brief Attaches a View (HTMLVideoElement) to the player.
   *        Note that for duplicating a view, the canvas concept (see example application) can be used as a more light-weight way of duplicating a video.
   * @param {HTMLVideoElement} aHtmlVideoElement - HTML Video Element.
   * @return {void}
   */
  attachView(aHtmlVideoElement: HTMLVideoElement): void;

  /**
   * @brief Returns a JSON array of Audio Track objects.
   *        See 'types' for a description of a SyeAudioTrack.
   * @return {SyeAudioTrack[]} A array of the available Audio Tracks.
   */
  audioTracks(): SyeAudioTrack[];

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Sets the HTMLVideoElements volume to a value from 0 to 1.0
   *        where 0 is muted and 1.0 is 100% of the system volume.
   * @param { number } aAudioVolume, a floating point integer in the range 0-1.0.
   * @return { void }
   */
  audioVolume(aAudioVolume: number): void;

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Returns an Array<string> of the Available Audio Languages
   * @return {Array<string>} The Available Audio Languages.
   */
  availableAudioLanguages(): Array<string>;

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Gets the available Closed Captions ChannelIndexes.
   * @return {Array<ChannelIndex>} The available ChannelIndexes
   */
  availableClosedCaptionsChannels(): Array<ChannelIndex>

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Returns the current playtime in localtime milliseconds
   * The returned value represents the time elapsed since the time origin.
   * The time origin is a standard time which is considered to be the beginning
   * of the current document's lifetime. Not to be confused with UTC time.
   * A return value of 0 implies no ongoing playback.
   * @return { number } - The current local play time in milliseconds.
   */
  currentPlayTimeLocalMillis(): number

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Returns a SyeAudioTrack object describing the current Audio Track, see 'types' for a description
   *        of the different properties of a SyeAudioTrack.
   * @return {SyeAudioTrack} A object describing the current Audio Track
   */
  currentAudioTrack(): SyeAudioTrack

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Returns the ID of the currently playing channel.
   * @return {string} The currently playing channel ID.
   */
  currentChannelId(): string

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Returns a SyeVideoTrack object describing the current Video Track
   *        See 'types' for a description of a SyeVideoTrack.
   * @return {SyeVideoTrack} A SyeVideoTrack describing the current Video Track or undefined.
   */
  currentVideoTrack(): SyeVideoTrack

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Detaches a View (HTMLVideoElement) from the player.
   * @param {HTMLVideoElement} aHtmlVideoElement - HTML Video Element.
   * @return {void}
   */
  detachView(aHtmlVideoElement: HTMLVideoElement): void

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Return if closed captions are currently parsed or not.
   * If closed captions are not parsed, onCEA608Callback will never be called.
   * @return { boolean } True if Closed Captions are parsed.
   */
  isClosedCaptionsEnabled(): boolean

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Returns the presentation delay in milliseconds.
   * @return {number} The presentation delay in milliseconds.
   */
  getPresentationDelayMillis(): number

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Returns the selected Closed Captions Index.
   * @return {ChannelIndex} The selected Closed Captions Index.
   */
  getSelectedClosedCaptionsChannel(): ChannelIndex


  /**
   * @brief Obtain statistics from the SyePlayer.
   *        Statistics will be reset at every channel change.
   *
   * @return a JSON formatted string containing the following parameters:
   *
   *  | parameter              | type   | description                                                  |
   *  | ---------------------- | ------ | ------------------------------------------------------------ |
   *  | state                  | string | Stopped|Loading|Playing|Stalled|AIS|Error                    |
   *  | numStalls              | int    | Number of playback stalls, i.e. number of times              |
   *  |                        |        | the player enters Stalled state.                             |
   *  | numDamagedAudioFrames  | int    | Number of damaged audio frames. This is either that the      |
   *  |                        |        | ADTS header was considered invalid, or that the complete     |
   *  |                        |        | frame was not able to be repaired in time through            |
   *  |                        |        | retransmission requests.                                     |
   *  | numDamagedVideoFrames  | int    | Number of damaged video frames. This is either that the      |
   *  |                        |        | H264/HEVC NAL-units were considered invalid, or that the     |
   *  |                        |        | complete frame was not able to be repaired in time through   |
   *  |                        |        | retransmission requests.                                     |
   *  | playDurationMillis     | int    | Playback duration in milliseconds                            |
   *  | egressAllocTimeMillis  | int    | Time (duration) of Egress Allocation, i.e.                   |
   *  |                        |        | Frontend HTTP(S) response time in milliseconds.              |
   *  | egressTTFFMillis       | int    | Time To First Frame from Egress.                             |
   *  | totalTTFFMillis        | int    | Total Time To First Frame in milliseconds.                   |
   *  |                        |        | This is measured from Play(...) request.                     |
   *  | totalBitsPerSecond     | int    | Current bitrate (audio, video, headers).                     |
   *  | currentRoundtripMillis | int    | Current roundtrip in milliseconds.                           |
   *  | numReceivedFrames      | int    | Number of received frames.                                   |
   *  | numDecryptedFrames     | int    | Number of decrypted audio/video frames.                      |
   */
  getStatistics(): string

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Pause - Pauses playback. This is an implicit stop.
   *        A call to playResume() afterwards will resume the playback
   *        with the previous play function called, live, timeshift or program mode.
   * @return { void }
   */
  pause(): void

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * Returns the current player state
   * @return {SyePlayerState} - The player state
   */
  playerState(): SyePlayerState

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * Plays the specified channel at the Live edge.
   * @param {string} aChannelId - A channel name to play.
   * @return { void }
   */
  playFromLive(aChannelId: string): void

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Play a timeshifted channel from live edge
   * @param {string} aChannelId - A channel name to play.
   * @param {number} aTimeShiftDurationMillis   Timeshift duration specified in milliseconds
   * @return { void }
   */
  playTimeshiftFromLive(aChannelId: string, aTimeShiftDurationMillis: number): void

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief PlayResume - To be called in case of an eror-retry callback.
   *                     This resumes the playback according to the previously chosen
   *                     play*() call (Live, Timeshift, Program).
   * @return { void }
   */
  playResume(): void

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Play a timeshifted channel from a specific UTC time position
   * @param {string} aChannelId                 A channel name to play.
   * @param {number} aTimeShiftDurationMillis   Timeshift duration specified in milliseconds
   * @param {number} aUtcTimePosMillis          Desired UTC time position
   * @return { void }
   */
  playTimeshiftFromUtcTime(aChannelId: string, aTimeShiftDurationMillis: number, aUtcTimePosMillis: number): void

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Play a timeshifted channel from a specific offset.
   *
   * This method is useful if you want synchronize several players, e.g. have them
   * play different channels at the same moment in time, e.g. different camera angles of the same event.
   *
   * Call synchronize on a player to obtain its current offset.
   *
   * @note This method is asynchronous. The player delegate is notified when playback has begun.
   * @attention Requires timeshift to be configured in the backend
   *
   * @param {string} aChannelId                 A channel name to play
   * @param {number} aTimeShiftDurationMillis   Timeshift duration specified in milliseconds
   * @param {number} aOffset                    A timeshift offset
   * @see synchronize
   */
  playTimeshiftFromOffset(aChannelId: string, aTimeShiftDurationMillis: number, aOffset: number): void

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Returns the selected Audio Language
   * @return {string} The selected Audio Language.
   */
  selectedAudioLanguage(): string

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Set audio language. This method does nothing if the language is already set.
   * @param {string} aAudioLanguage The Audio language to select.
   */
  selectAudioLanguage(aAudioLanguage: string): void

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Sets the selected Closed Captions ChannelIndex.
   * Valid range is 0-3, values outside that range throws a RangeError.
   * @return { void }
   */
  selectClosedCaptionsChannel(aClosedCaptionsChannelIndex: number): void

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Enables or Disables Closed Captions
   * @param {boolean} aClosedCaptionsEnabled True if closed captions shall be enabled, false otherwise.
   * @return {void}
   */
  setClosedCaptionsEnabled(aClosedCaptionsEnabled: boolean): void

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Function to set bitrate filtering of video tracks for a SyePlayer to use.
   *        A value of 0 means no threshold.
   *        This limits the number of available tracks for the player to perform ABR on.
   *        If no tracks satisfies the preferred range, the track lower than aLowerBoundBitrate
   *        is chosen. If aHigherBoundBitrate is lower than the the lowest bitrate, the track
   *        lower than aHigherBoundBitrate is chosen.
   *        Example:
   *          Tracks: 10, 20, 30
   *          Range (0,  5)  -> 10
   *          Range (10, 20) -> 10, 20
   *          Range (0,  25) -> 10, 20
   *          Range (15, 0)  -> 20, 30
   *          Range (19, 19) -> 10
   *          Range (11, 29) -> 20
   *          Range (40, 0)  -> 30
   *        The arguments must be positive numbers. Negative will result in a TypeError exception.
   * @param {number} aLowerBoundBitrate - Lower bound bitrate in bits/second.
   * @param {number} aHigherBoundBitrate - Higher bound bitrate in bits/second.
   * @return { void }
   */
  setPreferredBitrateFiltering(aLowerBoundBitrate: number, aHigherBoundBitrate: number): void

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * Stops the playback
   * @return { void }
   */
  stop(): void

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Used to synchronize other players with this player.
   * After this method is called a synchronization offset will be provided asynchronously in the SyePlayer..
   * Provide that offset as the offset parameter to your other players 'playTimeshift:fromOffset:timelineDurationMillis:' in order to get them play at the same offset as this player.
   * @param { (SyeSynchronizationData) } aSynchronizeCb - A synchronization callback function.
   * @see SyePlayer::playTimeshiftFromOffset
   * @see SyePlayer::onSynchronizationOffset
   */
  synchronize(aSynchronizeCb: (aSynchronizationData: SyeSynchronizationData) => void): void

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * Retrieves the thumbnail for the indicated UTC Time position.
   * The SyeThumbnail returned contains the Content-Type of the image and the raw image data.
   * The image data must be converted to a JPG image before display in the browser.
   *
   * @param { number } aUtcTimePosMillis - Time position for which a thumbnail shall be returned.
   * @param { SearchPredicate } aSearchPredicate - A search predicate to the thumbnail search, kClosest, kBackward, kForward
   * @return { SyeThumbnail | undefined } - A SyeThumbnail or undefined if a thumbnail cannot be found.
   */
  thumbnailForUtcMillis(aUtcTimePosMillis: number, aSearchPredicate: SearchPredicate): SyeThumbnail | undefined

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * Updates the SyeSystem used by the SyePlayer
   * @param {SyeSystem} aSyeSystem - The new SyeSystem
   */
  updateSyeSystem(aSyeSystem: SyeSystem): void

  // ---------------------------------------------------------------------------------------------------------------
  /**
   * @brief Returns the available video tracks as an Array of SyeVideoTracks.
   *        See 'types' for a description of a SyeVideoTrack.
   * @return {SyeVideoTrack[]} An array of the available Video Tracks.
   */
  videoTracks(): SyeVideoTrack[]

}


/**
* @enum SyePlayer RenderMode, Audio, Video or both.
*/
export enum RenderMode {
  /**
  * Default: Audio and Video
  */
   AudioAndVideo = 0,
  /**
  * Audio Only
  */
   AudioOnly     = 1,
  /**
  * Video Only
  */
   VideoOnly     = 2
}

/**
* @enum SyePlayer ABR Mode, No ABR, Stateful ABR or Non Stateful ABR
*/
export enum AbrMode {
   NoAbr = 0,
   StatefulAbr = 1,
   NonStatefulAbr = 2
}

/**
* @enum SyePlayer NAL Unit format, Annex B or AVCC
*/
export enum NalUnitFormat {
   AnnexB = 0,
   Avcc = 1
}

/**
* @enum SyePlayer Video Codec, H264 or HEVC
*/
export enum VideoCodec {
   H264 = 1,
   HEVC = 2
}

/**
* @enum SyePlayerError enum
*/
export enum SyePlayerError {
   // FrontEnd
   kNoError = "NoError",
   /**
   * Authentication Error, provide correct credentials.
   */
   kForbidden = "Forbidden",
   /**
   * Login: Authentication server error
   */
   kAuthServerError = "AuthServerError",
   /**
   * Login: Bad format of credentials, Stream: Please contact support
   */
   kBadRequest = "BadRequest",
   /**
   * The channel indicated in the play request was not found.
   */
   kChannelNotFound = "ChannelNotFound",
   /**
   * No Egress pitcher is currently available.
   */
   kNoPitcher = "NoPitcher",

   /**
   * Stream: Timeshift request out of range
   */
   kTimeshiftOutOfRange = "TimeshiftOutOfRange",
   /**
   * Server responded with an unexpected status, please contact support.
   */
   kUnexpectedStatus = "UnexpectedStatus",

   /**
   * Login/Stream: Internal server error, please contact support
   */
   kServerError = "ServerError",

   // Bazinga
   /**
   * The version on the client is not compatible with the server.
   */
   kUnsupportedVersion = "UnsupportedVersion",

   /**
   * DRM
   * Content is encrypted, but no license server is configured
   */
    kDRMNoLicenseManager = "DRMNoLicenseManager",

   /**
   * DRM
   * There was a problem fetching a license from the license manager
   */
    kDRMLicenseManagerError = "DRMLicenseManagerError",

   /**
   * DRM
   * An encrypted sample was received, but no decryption key is available.
   */
   kDRMNoKeyError = "DRMNoKeyError",

   /**
   * DRM
   * An encrypted sample was received, but decryption of the sample failed.
   */
   kDRMError = "DRMError",
   /**
   * No packets were received from the server, which was expected.
   */
   kCommunicationTimeout = "CommunicationTimeout",
   /**
   * Channel is not configured or otherwise unknown on Egress
   */
   kUnknownChannel = "UnknownChannel",
   /**
   * Track is unknown on the Egress node.
   */
   kUnknownTrack = "UnknownTrack",
   /**
   * Unexpected situation, please contact support
   */
   kException = "Exception",

   /**
   * Client is asked to reallocate the stream (on a new server)
   */
   kReallocateStream = "ReallocateStream",

   // FrontEnd/Egress
   /**
   * Unreachable or unresponsive server
   */
   kNetworkError = "NetworkError",
   /**
   * Error when trying to send to egress
   */
   kSendError = "SendError",

   /**
   * A bad value was used as a API function argument
   */
   kBadInput = "BadInput",


   // These are used when there was a call to BazPlayer::ReportError(...) with
   // BazPlayer::ErrorReport::kUnsupportedVideoFormat or
   // BazPlayer::ErrorReport::kUnsupportedAudioFormat or
   // BazPlayer::ErrorReport::kApplicationSuspended respectively
   kUnsupportedVideoFormat = "UnsupportedVideoFormat",
   kUnsupportedAudioFormat = "UnsupportedAudioFormat",
   kApplicationSuspended = "ApplicationSuspended"
}
/**
* @enum SyePlayerWarning enum
*/
export enum SyePlayerWarning {
   // FrontEnd
   /**
   * Another FrontEnd server will be contacted
   */
   kTryingNewURL,

   /**
   * Damaged samples were received from the server.
   */
   kDamagedSamples,
   /**
   * Client detected lost samples.
   */
   kLostSamples,
   /**
   * Reallocating streams.
   */
   kReallocatingStream,
}

/**
* @enum SyePlayerInfo enum
*/
export enum SyePlayerInfo {
   kAuthRequired,          //!< Client was told to log in again
   kABRChange,             //!< The Adaptive bitrate algorithm triggered a track change
   kManifestUpdate,        //!< TODO
   kNotification           //!< TODO
}

/**
* @enum SyeFetchChannelsError enum
*/
export enum SyeFetchChannelsError {
   /**
   * Sye Frontend responded with a 403 HTTP response code. Prompt for credentials.
   */
   kForbidden = "Forbidden",
   /**
   * Login: Bad format of credentials
   */
   kBadRequest = "BadRequest",
   /**
   * Login: Authentication server error
   */
   kAuthServerError = "AuthServerError",
   /**
   * Sye Frontend responded with an internal server error.
   */
   kServerError = "ServerError",
   /**
   * Sye SDK detected that it was not possible to contact a Sye Frontend given the URL.
   */
   kNetworkError = "NetworkError",
   /**
   * Sye SDK detected other error not described by the above status codes.
   */
   kUnexpectedStatus = "UnexpectedStatus",
   /**
   * @deprecated SyeFetchChannelsError.kNoPitcher is not used.
   * Sye Frontend responded with that there is no streaming server currently available.
   */
   kNoPitcher = "NoPitcher"
}

/**
* @class SyeFetchChannelsWarning enum
*/
export enum SyeFetchChannelsWarning {
   /**
   * Warning that the Sye SDK attempts to use the next available Sye Frontend URL.
   */
   kTryingNewUrl
}

/**
*@class Interface for a SyeChannel
*/
export class SyeChannel {
  /**
  * Channel Identity
  */
  channelId:          string
  /**
  * Channel Identity in the EPG
  */
  EPGId:              string
  /**
  * Channel VR mode type
  */
  type:               string
  /**
  * Specifies if the channel supports timeshift.
  */
  timeshiftSupported: boolean
 /*!
  * @property timeshiftDurationMillis
  * @brief The *configured* size of the timeshift buffer for this channel, as defined in the backend.
  *
  * @attention This value only represents the *configured* (i.e. the theoretically maximum) timeshift buffer size for this channel.
  * The actual timeshift buffer available on an Egress could be smaller than this value.
  * Also, different Egresses may have varying timeshift buffer sizes.
  */
  timeshiftDurationMillis: number

  /**
  * Constructor initializing members.
  */
  constructor()
  {
    this.channelId = '';
    this.EPGId = '';
    this.type = '';
    this.timeshiftSupported = false;
    this.timeshiftDurationMillis = 0;
  }
}
// ############################################
// CEA608
// ############################################

/**
* @enum ChannelIndex
*/
export enum ChannelIndex
{
  CC1,
  CC2,
  CC3,
  CC4
}

/**
* @enum enumeration for CEA608 Color
*/
export enum SyeCEA608Attribute {
  NoAttribute     = 0,
  // Foreground/Text
  Italics         = (1 << 0),
  Underline       = (1 << 1),
  Flashing        = (1 << 2),
  // Background
  Opaque          = (1 << 3),
  SemiTransparent = (1 << 4),
  Transparent     = (1 << 5)
}

export class SyeCEA608RowToken {
   pos:            number
   end:            number
   rowSubString:   string
   bgColor:        string
   textColor:      string
   bgAttribute:    SyeCEA608Attribute
   textAttributes: number
   constructor()
   {
      this.pos            = 0;
      this.end            = 0;
      this.rowSubString   = "";
      this.bgColor        = "";
      this.textColor      = "";
      this.bgAttribute    = SyeCEA608Attribute.NoAttribute;
      this.textAttributes = 0;
   }
}

/**
* @class Container for a SyeCEA608DisplayRow
*/
export class SyeCEA608DisplayRow {
   rowNumber: number
   tokens:    Array<SyeCEA608RowToken>
   constructor()
   {
      this.rowNumber = 0;
      this.tokens    = [];
   }
}

/**
* @class Container for a SyeCEA608Display
*/
export class SyeCEA608Display {
   localDisplayTime: number
   channel:          number
   renderDevice:     number
   rows:             Array<SyeCEA608DisplayRow>
   constructor()
   {
      this.localDisplayTime = 0;
      this.channel          = 0;
      this.renderDevice     = 0;
      this.rows             = [];
   }
}

/**
* @class SyeAPIConfig - Configuration object to the Sye Library
*/
export class SyeAPIConfig
{
  // Currently empty
   constructor()
   {
   }
}

/**
* @class SyePlayerConfig - Configuration object to a SyePlayer
*/
export class SyePlayerConfig {
   /**
    * @property renderMode - Player Rendering Mode:
    *                     RenderMode.AudioAndVideo
    *                     RenderMode.AudioOnly
    *                     RenderMode.VideoOnly
    */
   renderMode:                  RenderMode

   /**
    * @property nalUnitFormat - NAL Unit Format
    *                     NalUnitFormat.AnnexB
    *                     NalUnitFormat.Avcc
    */
   nalUnitFormat:               NalUnitFormat

   /**
    * @property presentationDelayMs - Presentation Delay
    *        The default value of 0 means a system defined presentation delay.
    */
   presentationDelayMs:         number

   /**
    * @property timeoutMs - Timeout in Milliseconds
    */
   timeoutMs:                   number

   /**
    * @property videoCodec - Video codec preference, VideoCodec.HEVC is currently not supported in the JavaScript SDK.
    *                     VideoCodec.H264
    *                     VideoCodec.HEVC
    */
   videoCodec:                  VideoCodec

   /**
    * @property useIPv6 - Specifies if the SyePlayer shall use IPv6 or not.
    */
   useIPv6:                     boolean

   /**
   * @property initialPreferredBitrate - Target bitrate at first playback.
   *                     Default values is 0 which means that the lowest bitrate will be used.
   */
   initialPreferredBitrate:     number

  /**
   * @property licenseManagerUrl
   * @brief HTTP URL to the DRM license manager.
   */
   licenseManagerUrl:           string
  /**
   * @property licenseManagerCredentials
   * @brief Credentials used to access the license manager
   */
   licenseManagerCredentials:   string

   constructor()
   {
      this.renderMode                = RenderMode.AudioAndVideo;
      this.nalUnitFormat             = NalUnitFormat.Avcc;
      this.presentationDelayMs       = 0;
      this.timeoutMs                 = 4000;
      this.videoCodec                = VideoCodec.H264;
      this.useIPv6                   = false;
      this.initialPreferredBitrate   = 0;
      this.licenseManagerUrl         = "";
      this.licenseManagerCredentials = "";
   }

   /**
   * @brief Method to set the URL for a 3rd party DRM License Server.
   * @param {string} aLicenseManagerUrl - An URL to License Server
   */
   public setLicenseManagerUrl(aLicenseManagerUrl: string): void
   {
     this.licenseManagerUrl = aLicenseManagerUrl;
   }

   /**
   * @brief Method to set the authentication credentials for a 3rd party DRM License Server.
   * @param {string} aLicenseManagerCredentials - An opaque string used for authentication.
   */
   public setLicenseManagerCredentials(aLicenseManagerCredentials: string): void
   {
      this.licenseManagerCredentials = aLicenseManagerCredentials;
   }
}

/**
* @interface SyePlayerAdditionalConfig - Internal 'special feature' configuration.
*/
export interface SyePlayerAdditionalConfig
{
    mInfluxKey: string,
    mInfluxUrl: string,
    mStatsKey: string
}

/**
* @interface Interface for a SyeThumbnail
*/
export interface SyeThumbnail {

  /**
  * Delay-adjusted UTC timestamp for the thumbnail.
  */
  utcServerTimeMillis: number

  /**
  * Content-Type of image
  */
  mimeType:           string

  /**
  * Image data
  */
  data:                Uint8Array
}

/**
* @interface Interface for SyeNotificationMessage
*/
export interface SyeNotificationMessage {
/*
 * @property messageId
 * @brief ID for this stream as configured in the backend
 * IDs 0..999 are reserved for internal use.
 */
  messageId:       number

/**
 * @property isSynchronized
 * @brief If true, this is a message that requires synchronization with the audio/video streams.
 * @note Realtime messages will not be sent in timeshift playback.
 */
  isSynchronized:  boolean

/**
 * @property isSticky
 * @brief If this notification is "sticky" or "one-off".
 *
 * If true, this message may or may not receive a corresponding clearing/cancellation message at a later point (i.e. a message with 'isCleared' set to true).
 * Sticky messages can also be overridden by a new sticky message without receiving a explicit cancellation message.
 *
 * If false, this message is a one-off message and will *not* receive a corresponding cancellation message.
 *
 * @see isCleared
 */
  isSticky:        boolean

/**
 * @property isCleared
 * @brief If this is a 'clear' notification of a sticky message.
 * @see isSticky
 */
  isCleared:       boolean

/**
 * @property presentationTimeMicros
 * @brief The presentation time of the message, in sync time.
 *
 * @attention This value is only valid if 'isSynchronized' is true.
 * @see isSynchronized
 */
  presentationTimeMicros: number

  /**
  * @property data
  * @brief The message data, if any.
  */
  data:            Uint8Array
}

/**
* @enum SearchPredicate, Search Predicate for Thumbnail search.
*/
export enum SearchPredicate
{
  /**
  * @brief Search for closest thumbnail, forward or backwards.
  */
  kClosest,
  /**
  * @brief Search for closest thumbnail, backwards.
  */
  kBackward,
  /**
  * @brief Search for closest thumbnail, forward.
  */
  kForward
}

/**
* @interface Interface for a SyeSynchronizationData
*/
export interface SyeSynchronizationData {
  /**
  * Offset, the offset can be used to start another player to acheive synchronized timeshift
  */
  offset: number
}

/**
* @interface Interface for a SyeTimelineInfo
*/
export interface SyeTimelineInfo {
    /**
    *  Playback type
    *     PlaybackType::kNotSet
    *     PlaybackType::kLive         - Play live without timeshift buffer
    *     PlaybackType::kTimeshift    - Play live with a timeshift buffer to seek/pause/resume within
    *     PlaybackType::kProgram      - Play a channel from a start time to an end time
    *                                   @note A program end time could be in the future (ongoing program)
    */
    playbackType: SyePlaybackType

    /**
    * Start of timeline in UTC milliseconds
    *     PlaybackType::kLive         - Current live position 
    *     PlaybackType::kTimeshift    - Earliest seakable time within the timeshift buffer
    *     PlaybackType::kProgram      - Time of program start
    */
    timelineStartUtcMillis: number

    /**
    * End of timeline in UTC milliseconds
    *     PlaybackType::kLive         - Current live position
    *     PlaybackType::kTimeshift    - Current live position
    *     PlaybackType::kProgram      - Time of program end
    */
    timelineEndUtcMillis: number

    /**
    * Current playback position in UTC milliseconds
    *     PlaybackType::kLive         - Current playback position (equals live position)
    *     PlaybackType::kTimeshift    - Current playback position
    *     PlaybackType::kProgram      - Current playback position
    */
    currentPosUtcMillis: number

    /**
    * Current live edge position in UTC milliseconds
    *     PlaybackType::kLive         - Current live position
    *     PlaybackType::kTimeshift    - Current live position
    *     PlaybackType::kProgram      - Current live position This can be smaller than timelineEndUtcMillis, which
    *                                   This will be smaller than timelineEndUtcMillis, if program end is in the future.
    */
    livePosUtcMillis: number

    //! Earliest available timeshift buffer start UTC milliseconds.
    //!     PlaybackType::kLive         - 0
    //!     PlaybackType::kTimeshift    - Earliest time within the server timeshift buffer (not necessarily seekable)
    //!     PlaybackType::kProgram      - Time of program start
    availabilityStartUtcMillis: number

    /**
    * If the timeline should be visible or hidden from the user
    *     PlaybackType::kLive         - false
    *     PlaybackType::kTimeshift    - true
    *     PlaybackType::kProgram      - true
    */
    showTimeline: boolean
}


/**
* @interface Interface for a SyeVideoTrack
*/
export interface SyeVideoTrack {
   /**
   * Unique track ID
   * This corresponds to the track ID for H264/HEVC samples
   */
   trackId: number

   /**
   * Width of video frames for this track
   * Number of pixels, e.g. 1920
   */
   width: number

   /**
   * Height of video frames for this track
   * Number of pixels, e.g. 1080
   */
   height: number

   /**
   * Bitrate of video stream for this track
   * Bits per second, e.g 2439187 (~2.5 Mbps)
   */
   bitrate: number

   /**
   * MPEG Transport Stream PSI Elementary stream type
   * See https://en.wikipedia.org/wiki/Program-specific_information
   * E.g 27 (H.264), 36 (H.265/HEVC)
   */
   tsStreamType: number

   /**
   * Video stream codec for this track
   * kVideoCodecH264 or kVideoCodecHEVC
   */
   codec: number
}

/**
* @interface Interface for a SyeAudioTrack
*/
export interface SyeAudioTrack {
   /**
   * Unique track ID
   * This corresponds to the track ID for ADTS samples
   */
   trackId:             number

   /**
   * MPEG Transport Stream PSI Program Element Descriptor Tag (0x0A)
   * If not set, this is a empty string
   */
   language:            string

   /**
   * Bitrate of audio stream for this track
   * Bits per second, e.g 144384
   */
   bitrate:             number

   /**
   * Samplerate of audio stream for this track in Hz
   *  E.g. 48000
   */
   samplerate:          number

   /**
   * Number of channels in audio stream for this track
   * E.g. 2 (Stereo)
   */
   numChannels:         number

   /**
   * MPEG Transport Stream PSI Elementary stream type
   * See https://en.wikipedia.org/wiki/Program-specific_information
   * E.g. 15 (ADTS AAC MPEG-2 lower bit-rate audio)
   */
   tsStreamType:        number
   /**
   * AAC
   */
   codec:               number
}

/**
* @enum SyePlayerState - State for the SyePlayer
*/
export enum SyePlayerState {
   /**
   * Player is currently 'Stopped'
   */
   kStopped,
   /**
   * Player is currently loading the media.
   */
   kLoading,
   /**
   * Player is currently 'Playing'
   */
   kPlaying,
   /**
   * Player is currently 'Stalled', no Media samples have been received.
   */
   kStalled,
   /**
   * Player has a server connection but server does not receive video from upstreams.
   */
   kAIS,
   /**
   * Player is currently in 'Error' state
   */
   kError
}

/**
* @class SyeFrontend - Configuration to be used to access the SyeFrontend, URL and credentials.
*/
export class SyeFrontend {
  /**
  * Base URL to use to the Sye Front-End on the format <scheme><host>[port], e.g. http://1.2.3.4:8080.
  * Port must be specified if the port deviates from the standard port for the selected scheme.
  */
   baseUrl:     string
  /**
  * Credentials to use for logging in. This must be a JSON stringified string containing the properties 'username' and 'password'.
  */
   credentials: string

   /**
   * Constructor, performs default initialization of all members.
   */
   constructor()
   {
     this.baseUrl = '';
     this.credentials = '';
   }
}

/**
* @class SyeSystem - Contains an Array of SyeFrontends, multiple URLs to SyeFrontends can be specified
*        by adding a SyeFrontend in SyeSystem.syeFrontends.push(SyeFrontend)
*/
export class SyeSystem {
   syeFrontends: SyeFrontend[]
   constructor()
   {
      this.syeFrontends = new Array<SyeFrontend>();
   }
}

/**
* @enum SyePlaybackType
*/
export enum SyePlaybackType
{
    /**
    * Live without timeshift buffer
    */
    kLive,
    /**
    * Live with timeshift buffer
    */
    kTimeshiftLive,

    /**
    * Timeshift with offset from live.
    */
    kTimeshiftOffset,

    /**
    * Timeshift from UTC Position.
    */
    kTimeshiftUtcPos
}

/**
* @class Sye View Resolution - Used internally in the SDK to convey the display resolution of the HTML element.
*/
export class SyeViewResolution {
  heightPx: number
  widthPx:  number

  constructor(){
    this.heightPx = 0;
    this.widthPx  = 0;
  }
}

/**
 * @interface Interface for a SyeEgressAllocationEvent
 */
export interface SyeEgressAllocationEvent {

  /**
   * The time it took to receive a successful egress-allocation response from the Sye Frontend, measured from the latest SyePlayer::Play() request
   */
  allocationTimeMillis: number
}

/**
 * @interface Interface for a SyeEgressContactEvent
 */
export interface SyeEgressContactEvent {

  /**
   * The time it took to receive the first UDP packet from the egress, measured from first sent UDP packet, in milliseconds
   */
  timeToResponseMillis: number
}

/**
 * @interface Interface for a SyeEgressFrameEvent
 */
export interface SyeEgressFrameEvent {
  /**
   * The time it took to receive the first video frame from the egress, measured from first sent UDP packet
   */
  timeToFirstFrameMillis: number

  /**
   * The total time it took to receive the first video frame from the egress, measured from the Play(...) request.
   */
  totalTimeToFirstFrameMillis: number

}