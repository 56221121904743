import React from 'react'
import Dialog from '@material-ui/core/Dialog/Dialog'
import Slide from '@material-ui/core/Slide/Slide'
import HeaderRow from '../common/header/HeaderRow'
import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import IconButton from '@material-ui/core/IconButton/IconButton'
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { createStyles, Theme, WithStyles } from '@material-ui/core'
import { VideoView } from '../../sye/VideoView'
import { PlayerManager, SyeConnectionDetails } from '../../sye/PlayerManager'
import { EventSubscription } from 'fbemitter'
import InputFeed from '../../model/InputFeed'
import { SyeVideoTrack } from '../../../lib/sye/types'

const styles = (theme: Theme) => createStyles({

})

const VIDEO_SURFACE_ID = "mainScreen"

interface Props extends WithStyles<typeof styles> {
  inputFeed: InputFeed
  onClose: () => void
}

interface State {
  isOpen: boolean
  currentVideoTrack: SyeVideoTrack
}

class ViewFeedPage extends React.PureComponent<Props, State> {
  private mPlayerManager!: PlayerManager
  private readonly mSyeVideoViewRef: React.RefObject<VideoView> = React.createRef()
  private readonly mPlayerCallbackSubscriptions = new Array<EventSubscription>()

  constructor(props: Props) {
    super(props)
    this.state = {
      isOpen: true,
      currentVideoTrack: {width: 1920, height: 1080, bitrate: -1, trackId: -1, tsStreamType: -1, codec: -1},
    }

    const connectionDetails = new SyeConnectionDetails(
      this.props.inputFeed.syeBaseUrl,
      this.props.inputFeed.channelId,
      0,
      this.props.inputFeed.username,
      this.props.inputFeed.password
    );
    this.mPlayerManager = new PlayerManager(connectionDetails)

    this.onErrorReceived = this.onErrorReceived.bind(this)
    this.onRetryableErrorReceived = this.onRetryableErrorReceived.bind(this)
    this.onCurrentVideoTrackChange = this.onCurrentVideoTrackChange.bind(this)

    this.onCloseButtonClicked = this.onCloseButtonClicked.bind(this)
    this.getTransitionComponent = this.getTransitionComponent.bind(this)

    this.setupSyePlayer = this.setupSyePlayer.bind(this)
    this.teardownSyePlayer = this.teardownSyePlayer.bind(this)

  }


  componentWillUnmount() {
    this.teardownSyePlayer()
  }

  private setupSyePlayer() {
    /// Attach the video surface to the player
    this.mPlayerManager.attachVideoSurface(this.mSyeVideoViewRef.current!.mVideoSurfaceRef.current!, VIDEO_SURFACE_ID)

    /// Subscribe to relevant notifications
    const emitter = this.mPlayerManager.getEventEmitter()

    const errorSub = emitter.addListener('onError', this.onErrorReceived)
    this.mPlayerCallbackSubscriptions.push(errorSub)

    const retryableErrorSub = emitter.addListener('onErrorRetry', this.onRetryableErrorReceived)
    this.mPlayerCallbackSubscriptions.push(retryableErrorSub)

    const videoTrackSub = emitter.addListener('onVideoTrackChange', this.onCurrentVideoTrackChange)
    this.mPlayerCallbackSubscriptions.push(videoTrackSub)

    /// Begin playback
    this.mPlayerManager.play(this.props.inputFeed.channelId)
  }

  private teardownSyePlayer() {
    this.mPlayerManager.detachVideoSurface(VIDEO_SURFACE_ID)
    this.mPlayerCallbackSubscriptions.forEach(subscription => subscription.remove())
    this.mPlayerManager.stop()
  }

  private onErrorReceived(aCode: number, aDescription: string) {
    const formattedMsg = 'Error: ' +  aCode + ' : ' + aDescription;
    console.log(formattedMsg);
    //FIXME: Add nice alert
    window.alert(formattedMsg)
  }

  private onRetryableErrorReceived(aCode: number, aDescription: string, aRetryAfterMillis: number) {
    const formattedMsg = 'Error: ' +  aCode + ' : ' + aDescription + ' retry (ms): ' + aRetryAfterMillis
    console.log(formattedMsg);
    //FIXME: Add nice alert
    window.alert(formattedMsg)
  }

  private onCurrentVideoTrackChange(aFrom?: SyeVideoTrack, aTo?: SyeVideoTrack) {
    /// Manually trigger onDimensionsUpdated() in order to get correct initial size
    const isStartingPlayback = aFrom === undefined
    if (isStartingPlayback && this.mSyeVideoViewRef.current) {
      this.mSyeVideoViewRef.current.onDimensionsUpdated()
    }

    if (!aTo) { return }
    this.setState({currentVideoTrack: aTo})
  }

  private onCloseButtonClicked() {
    this.setState({isOpen: false})
  }

  private getTransitionComponent(props: Props) {
    return <Slide direction="up" {...props} />
  }

  render() {
    return (
      <Dialog
        fullScreen
        open={this.state.isOpen}
        onClose={this.onCloseButtonClicked}
        onExited={this.props.onClose}
        onRendered={this.setupSyePlayer}
        TransitionComponent={this.getTransitionComponent}>

        <HeaderRow
          title={`Feed ${this.props.inputFeed.channelId}`}
          style={{margin: 0}}
        >
          <Toolbar>
            <IconButton onClick={this.onCloseButtonClicked} aria-label="Close">
              <CloseIcon/>
            </IconButton>
            <Typography variant="h6" color="inherit">
            </Typography>
          </Toolbar>
        </HeaderRow>

        <VideoView
          ref={this.mSyeVideoViewRef}
          videoRotationAngle={0}
          currentVideoTrack={this.state.currentVideoTrack}
          isVisible={true}/>

      </Dialog>
    )
  }
}

const withStyled = withStyles(styles)(ViewFeedPage)
export default withStyled