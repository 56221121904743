import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";


import { createStyles, Theme, WithStyles } from '@material-ui/core'
import { defaultFontProperties } from '../../../styles'
import SimpleListMenu from '../../common/menu/SimpleListMenu'
import Affiliate from '../../../model/Affiliate'

const styles = (theme: Theme) => createStyles({
  list: {
    ...defaultFontProperties,
    fontSize: "14px",
    listStyle: "none",
    margin: 0,
    padding: 0,
    color: "inherit",
    height: '100%',
    //backgroundColor: 'red',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',


    [theme.breakpoints.down("sm")]: {
      height: 'auto',
      flexDirection: 'column',
      alignItems: 'flex-start',

      "&:after": {
        content: '""',
        display: "block",
        height: "1px",
        width: '100%',
        backgroundColor: theme.palette.divider,
        //backgroundColor: 'red'
      }
    },

  },
  listItem: {
    width: "auto",
    height: '100%',
    padding: 0,
    marginLeft: "20px",
    marginRight: "20px",

    //backgroundColor: 'green',

    [theme.breakpoints.down("sm")]: {
      height: '50px',
      width: "100%",
      marginTop: "8px",
      marginBottom: "8px",
    },
  },
});


interface Props extends WithStyles<typeof styles>{
  affiliates: Affiliate[]
  selectedAffiliateIndex: number
  onAffiliateSelected: (index: number) => void
}

interface State {}

class AppNavigationBarLinks extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <List className={this.props.classes.list}>
        <ListItem className={this.props.classes.listItem}>
          <SimpleListMenu title={"Affiliate"}
                          options={this.props.affiliates.map(af => af.name)}
                          selectedIndex={this.props.selectedAffiliateIndex}
                          onSelect={this.props.onAffiliateSelected}
          />
        </ListItem>
      </List>
    );
  }
}

export default withStyles(styles)(AppNavigationBarLinks);
