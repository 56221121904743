import { OutputReducerState } from '../reducers/OutputReducer'
import Output from '../../model/Output'

export class OutputSelectors {

  public static outputsBelongingToAffiliateId(affiliateId: string | null, state: OutputReducerState): Output[] {
    if (affiliateId === null) {
      return []
    }
    const outputs = state[affiliateId]
    return outputs ? outputs : []
  }
}